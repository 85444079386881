import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mContent: {
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(10, 12),
    backgroundColor: theme.colors.form__background_primary,
    '&:first-child': {
      paddingTop: theme.spacing(10)
    }
  },
  blockContent: {
    position: 'relative',
    padding: theme.spacing(10, 12),
    backgroundColor: theme.colors.form__background_primary,
    '&:first-child': {
      paddingTop: theme.spacing(10)
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  }
}));
