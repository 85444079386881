import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import FormList from 'core/components/form-list';
import { useDisabledState } from 'common/disabled-context';
import AddApplicant from 'common/components/people/add-form';
import { SchedulePP } from 'common/components/schedule-pp';
import { Umbrella } from 'common/components/umbrella';
import { Additionals, HomeCoverage } from 'common/components/home/coverages';
import Detail from 'common/components/home/detail';
import Discounts from '../../discounts';
import Applicant from '../applicant/applicant';

const HomePolicy = ({ repEmail }) => {
  const session = useContext(AuthContext);
  const { values } = useFormikContext();
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  const {
    homeCoverage: { coverageX, coverageCPctOfA, coverageA },
    home: {
      homeLocation: { state }
    }
  } = values;

  const allowLicensedActions = policyStore.getAllowLicensedActions(session);
  const { disabled } = useDisabledState();

  const sppLimits = {
    tenPctOfCoverageC: coverageA * (coverageCPctOfA / 100) * 0.1,
    fiftyPctOfCoverageC: coverageA * (coverageCPctOfA / 100) * 0.5
  };

  return (
    <>
      <FormList
        disabled={disabled || !allowLicensedActions}
        id="people"
        title="People"
        item={Applicant}
        addForm={AddApplicant}
        addLabel="Add People"
        fromPolicy
      />
      <Detail disabled={disabled} fromPolicy />
      <HomeCoverage disabled={disabled} fromPolicy />
      <Additionals disabled={disabled} fromPolicy repEmail={repEmail} />
      <Umbrella disabled={disabled} fromPolicy state={state} coverageX={coverageX} />
      <Discounts disabled={disabled} />
      <SchedulePP disabled={disabled} sppLimits={sppLimits} />
    </>
  );
};

HomePolicy.propTypes = {
  repEmail: PropTypes.string.isRequired
};

export default observer(HomePolicy);
