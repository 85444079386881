import portal from 'core/assets/svg/portal.svg';
import contact from 'core/assets/svg/contact.svg';
import pieChart from 'core/assets/svg/pie-chart.svg';
import pen from 'core/assets/svg/pen.svg';
import tool from 'core/assets/svg/tool.svg';
import puzzlePiece from 'core/assets/svg/puzzle-piece.svg';
import announcement from 'core/assets/svg/announcement.svg';
import claim from 'core/assets/svg/claim.svg';

export default [
  {
    route: 'updates',
    src: 'https://v2-embednotion.com/Welcome-to-Branch-f79f122fa8bb4c6d80d82b4ebbb7e00a',
    title: 'Updates',
    iconSrc: portal
  },
  {
    route: 'reports-and-commissions',
    src: 'https://v2-embednotion.com/Reports-Commissions-5d1e65a08fdd4c27a52d5f9287ee499f',
    title: 'Reports & Commissions',
    iconSrc: pieChart
  },
  {
    route: 'underwriting',
    src: 'https://v2-embednotion.com/Underwriting-c44e478eb85e4369ba6fa0ab07f26c74',
    title: 'Underwriting',
    iconSrc: pen
  },
  {
    route: 'tools',
    src: 'https://v2-embednotion.com/Tools-and-Resources-98730a3c688c4c4b86b235ee84ce981e',
    title: 'Resources',
    iconSrc: tool
  },
  {
    route: 'training',
    src: 'https://v2-embednotion.com/Training-473fbb17df5f41bb9080164f25205d51',
    title: 'Training',
    iconSrc: puzzlePiece
  },
  {
    route: 'marketing',
    src: 'https://v2-embednotion.com/Marketing-4898e50ae5ab4336b772e3d5c11c5305',
    title: 'Marketing',
    iconSrc: announcement
  },
  {
    route: 'claims',
    src: 'https://v2-embednotion.com/Claims-9a767baeb2c0464c80a847642629e720',
    title: 'Claims',
    iconSrc: claim
  },
  {
    route: 'contact-us',
    src: 'https://v2-embednotion.com/Contact-Us-29421786cff74f0e806dff853085cf19',
    title: 'Contact Us',
    iconSrc: contact
  }
];
