import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';

import { LabelTooltip } from 'core/components/label-tooltip';
import { Select } from 'core/components/select';
import { tooltipHoverTexts } from 'core/helpers/constants';
import usePIPCoverageOptions from './hooks/use-pip-coverage-options';
import useAttendanceCareOptions from './hooks/use-attendance-care-options';
import useStyles from './8-choose-pip-and-attendance-care.styles';

const ChoosePipAndAttendanceCare = ({ context, onUpdate }) => {
  const classes = useStyles();
  const pipMEOptions = usePIPCoverageOptions(context);
  const pipACROptions = useAttendanceCareOptions(context);

  useEffect(() => {
    if (!pipMEOptions.some((option) => option.id === context.data.policyLimitPIPME)) {
      onUpdate({ policyLimitPIPME: pipMEOptions[0].id });
    }
  }, []);

  useEffect(() => {
    if (!pipACROptions.some((option) => option.id === context.data.policyLimitPIPACR)) {
      onUpdate({ policyLimitPIPACR: pipACROptions[0].id });
    }
  }, [context.data.policyLimitPIPME]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <LabelTooltip
          label="PIP Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitPIPME }}
          mode="light"
        >
          <Select
            type="select"
            className={classes.select}
            value={context.data.policyLimitPIPME}
            options={pipMEOptions}
            onChange={(_, value) => onUpdate({ policyLimitPIPME: value })}
            mode="light"
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label="Attendant Care"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitPIPACR }}
          mode="light"
        >
          <Select
            type="select"
            className={classes.select}
            value={context.data.policyLimitPIPACR}
            options={pipACROptions}
            onChange={(_, value) => onUpdate({ policyLimitPIPACR: value })}
            mode="light"
          />
        </LabelTooltip>
      </Grid>
    </Grid>
  );
};

export default ChoosePipAndAttendanceCare;
