import React, { useMemo } from 'react';

import { Card } from 'core/components/card';
import { PersonCheckbox } from '../components/person-checkbox';
import { PersonsSection } from '../components/persons-section';
import { getPersonAge } from '../helpers';

const WaiveWorkLossCoverage = ({ context, onUpdate }) => {
  const seniorResidents = useMemo(
    () =>
      [...context.data.drivers, ...context.data.nonDrivers].filter(
        (r) => getPersonAge(r.dateOfBirth) >= 60 && !r.pipOptOut
      ),
    [context.data.drivers, context.data.nonDrivers]
  );

  return (
    <PersonsSection>
      <Card>
        {seniorResidents.map((resident) => (
          <PersonCheckbox
            id={`${resident.id}-waivedPIPWL`}
            key={resident.id}
            name={`${resident.id}-waivedPIPWL`}
            person={resident}
            value={resident.waivedPIPWL}
            onChange={(checked) => {
              let updatedList = [];
              let key = 'drivers';
              let index = context.data.drivers.findIndex((d) => d.id === resident.id);
              if (index !== -1) {
                updatedList = [...context.data.drivers];
              } else {
                key = 'nonDrivers';
                index = context.data.nonDrivers.findIndex((r) => r.id === resident.id);
                updatedList = [...context.data.nonDrivers];
              }
              updatedList[index].waivedPIPWL = checked;
              onUpdate({ [key]: updatedList });
            }}
          />
        ))}
      </Card>
    </PersonsSection>
  );
};

export default WaiveWorkLossCoverage;
