import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: 720,
    margin: '0 auto',
    padding: theme.spacing(6, 6, 4),
    overflow: 'hidden'
  },
  overflowContainer: {
    composes: '$container',
    overflow: 'visible'
  },
  subtitle: {
    fontSize: 14
  },
  withBottomLine: {
    borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`,
    paddingBottom: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  noMegaDownPayWarning: {
    backgroundColor: theme.colorPalette.green_30,
    width: '100%',
    height: 88,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: theme.spacing(7, 5),
    marginTop: theme.spacing(3),
    borderRadius: 6,
    marginBottom: theme.spacing(4)
  },
  billingDetails: {
    marginTop: theme.spacing(4)
  },
  warningIcon: {
    height: 32,
    width: 32
  },
  warningText: {
    color: theme.colorPalette.beige_10,
    marginLeft: theme.spacing(4),
    width: 524
  }
}));

export default useStyles;
