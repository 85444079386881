import cloneDeep from 'lodash-es/cloneDeep';
import omit from 'lodash-es/omit';
import pick from 'lodash-es/pick';
import { policyTypes } from '@ourbranch/policy-types';
import { earthquakeStates, floodStates, combinedUMUIM } from '@ourbranch/lookups';
import { awsDateFormatter } from './formatters';
import { CondoType, condoTypesWithEntry, condoTypesWithUnitsInBuilding } from './constants';

export const mapRequestQuoteToInputType = (data, errorCodes = []) => {
  const quote = {
    address: data.address.trim(),
    city: data.city.trim(),
    state: data.state,
    zip: data.zip.trim(),
    rep: data.rep,
    affinity: data.affinity,
    encryptedData: data.encryptedData,
    fname: data.firstName.trim(),
    lname: data.lastName.trim(),
    isApartment: (data.isApartment || !!data.unit) && data.policyType.includes('R'),
    unit: data.unit ? data.unit.trim() : undefined,
    email: data.email ? data.email.trim() : undefined,
    phone: data.phone ? data.phone.trim() : undefined,
    additionalPhoneNumbers: data.additionalPhoneNumbers ? data.additionalPhoneNumbers : [],
    dateOfBirth: data.dateOfBirth ? awsDateFormatter(data.dateOfBirth) : null,
    leadSource: data.leadSource,
    currentAutoCarrier: data.currentAutoCarrier ? data.currentAutoCarrier.trim() : null,
    currentAutoLimitBIPD: data.policyLimitBIPD || null,
    continuousAutoCoverageYears:
      data.priorCoverageInYears || data.priorCoverageInYears === 0 ? parseInt(data.priorCoverageInYears, 10) : null,
    driversLicenseState: data.driversLicenseState ? data.driversLicenseState : null,
    driversLicenseNumber: data.driversLicenseNumber ? data.driversLicenseNumber.trim() : null,
    insuranceInPast31Days: !!data.insuranceInPast31Days,
    connectedHome: data.connectedHome,
    global: data.global,
    fromStaff: true,
    policyType: data.policyType
  };
  if (data.priorAddress?.address) {
    quote.priorAddress = omit(data.priorAddress, '__typename');
  }
  if (data.home) {
    quote.home = omit(data.home, [
      '__typename',
      'county',
      'homeLocation',
      'mortgageDetails',
      'replacementCostEstimate',
      'fpc',
      'wildfireHazardScore',
      'dtoName',
      'dtbowName',
      'dtbowLabel'
    ]);
  }
  if (data.condo) {
    quote.condo = omit(data.condo, ['__typename', 'county', 'condoLocation', 'mortgageDetails', 'monthsRentedOut']);
    quote.condo.hasUnitNumber = !data.noUnitNumber;
    quote.condo.weeksRentedOut = data.condo.monthsRentedOut ? data.condo.monthsRentedOut * 4 : 0;
    if (!condoTypesWithEntry.includes(data.condo.typeOfCondo)) {
      quote.condo.typeOfEntry = null;
    }
    if (!condoTypesWithUnitsInBuilding.includes(quote.condo.typeOfCondo)) {
      quote.condo.buildingHasMoreThanFiveUnits = quote.condo.typeOfCondo !== CondoType.DETACHED;
    }
  }
  if (data.VIN) {
    quote.VIN = data.VIN.trim();
  }
  return quote;
};

export const mapRevisedQuinstreetQuoteDetails = (quote) => {
  const revisedQuote = omit(mapRevisedQuoteDetails(quote), [
    'home.homeLocation',
    'homeCoverage.minimumHurricaneDeductible',
    'homeCoverage.minimumHurricaneDeductibleValue'
  ]);

  return {
    ...pick(revisedQuote, [
      'fname',
      'lname',
      'email',
      'includeUmbrella',
      'includeRenters',
      'isHomeOwner',
      'leadSource',
      'rep',
      'selectedOption',
      'noBindHome',
      'noBindAuto',
      'fromStaff',
      'includeEarthquake',
      'includeFlood',
      'getMVRs',
      'monthsAtCurrentAddress'
    ]),
    priorAddress: revisedQuote.priorAddress ? omit(revisedQuote.priorAddress, ['__typename']) : undefined,
    correctedAddress: revisedQuote.correctedAddress ? omit(revisedQuote.correctedAddress, ['__typename']) : undefined,
    auto: revisedQuote.auto,
    drivers: quote.drivers
      ? quote.drivers.map((driver) => ({
          ...pick(driver, [
            'id',
            'isPrimary',
            'isCoApplicant',
            'firstName',
            'lastName',
            'suffix',
            'dateOfBirth',
            'gender',
            'excludeDriver',
            'maritalStatus',
            'highestEducation',
            'driversLicenseState',
            'driversLicenseNumber',
            'ageFirstLicensed',
            'fullTimeStudent',
            'goodStudent',
            'teenDiscount',
            'courseDiscountCOnditionCheck',
            'takenCourse',
            'schoolName',
            'communityDriveParticipation',
            'waivedPIPWL',
            'pipElgible',
            'pipOptOut',
            'pipHasQHC',
            'assignedCar',
            'gotDrivingRecord',
            'clearUDRViolation',
            'needSR22',
            'autoViolationDates'
          ])
        }))
      : undefined,
    home: revisedQuote.home,
    homeCoverage: revisedQuote.homeCoverage,
    autoCoverage: revisedQuote.autoCoverage,
    existingCustomer: revisedQuote.existingCustomer,
    inviter: revisedQuote.inviter,
    connectedHome: revisedQuote.connectedHome,
    earthquakeCoverage: revisedQuote.earthquakeCoverage,
    floodCoverage: revisedQuote.floodCoverage,
    umbrellaCoverage: revisedQuote.umbrellaCoverage,
    trailers: revisedQuote.traileers,
    cars: quote.cars
      ? quote.cars.map((car) =>
          omit(
            car,
            'lastSevereIncidentDate',
            '__typename',
            'bodyType',
            'garageLocation.__typename',
            'addDate',
            'deductibleCollisionInitial',
            'deductibleComprehensiveInitial',
            'limitRentalInitial',
            'roadsideAssistanceInitial'
          )
        )
      : undefined,
    global: {
      ...pick(revisedQuote.global, [
        'autoEffectiveDate',
        'homeEffectiveDate',
        'currentlyHomeInsured',
        'manualInventorySubmission',
        'discountInventoryScore',
        'discountInventoryScoreAddedDate',
        'discountDriverScore',
        'discountPaperless',
        'homeownersPaymentType',
        'homeownersPaymentMethod',
        'autoPaymentType',
        'autoPaymentMethod',
        'currentlyAutoInsured',
        'currentAutoCarrier',
        'currentAutoCarrierStartDate',
        'currentAutoCarrierEndDate',
        'currentAutoCarrierPolicyNumber',
        'continuousAutoCoverageStartDate',
        'mostRecentAutoInsuranceCoverageEndDate',
        'breakupWithAuto',
        'currentHomeownersCarrier',
        'currentHomeownersCarrierPolicyNumber',
        'breakupWithHomeowners',
        'personalPropertyProtection',
        'emails',
        'phoneNumbers',
        'mailingAddress',
        'billingDayOfMonth',
        'autoBillingDayOfMonth',
        'homeBillingDayOfMonth',
        'affinity',
        'homeSecurityPartnerCustomerType',
        'employeeDiscount',
        'priorCancelNonPay'
      ]),
      priorIndividualBILimit: quote.global.priorIndividualBILimit,
      priorOccurrenceBILimit: quote.global.priorOccurrenceBILimit
    }
  };
};

const cleanMortgageDetails = (dwelling) => {
  dwelling.mortgageDetails = dwelling?.mortgageDetails?.map((m) =>
    omit(m, ['__typename', 'county', 'mortgageHolderAddress.__typename'])
  );
};

export const mapRevisedQuoteDetails = (variables) => {
  const {
    cars,
    trailers,
    drivers,
    removedDrivers,
    home,
    homeCoverage,
    autoCoverage,
    auto,
    rentersCoverage,
    umbrellaCoverage,
    condo,
    condoCoverage,
    global,
    priorAddress,
    includeUmbrella,
    includeRenters,
    includeEarthquake,
    includeFlood,
    earthquakeCoverage,
    floodCoverage,
    isHomeOwner,
    scheduledPersonalProperty,
    leadSource,
    selectedOption,
    existingCustomer,
    fname,
    lname,
    connectedHome,
    fromStaff,
    rep,
    getMVRs,
    inviter,
    email,
    correctedAddress,
    preBindUWCheck,
    overrides
  } = variables;

  // clone to clean mortgageDetails
  const homeCopy = cloneDeep(home);
  if (homeCopy) {
    cleanMortgageDetails(homeCopy);
  }
  if (condo) {
    cleanMortgageDetails(condo);
  }

  const isNonRentersBundle = ['HA', 'HB', 'AB', 'CA'].includes(selectedOption);
  return {
    fname,
    lname,
    email,
    selectedOption,
    fromStaff,
    getMVRs,
    noBindAuto: selectedOption === 'HB',
    noBindHome: selectedOption === 'AB',
    existingCustomer: existingCustomer ? omit(existingCustomer, ['__typename']) : null,
    includeUmbrella: includeUmbrella && isNonRentersBundle,
    includeRenters,
    includeEarthquake,
    includeFlood,
    earthquakeCoverage: earthquakeCoverage ? omit(earthquakeCoverage, ['__typename']) : undefined,
    floodCoverage: floodCoverage ? omit(floodCoverage, ['__typename']) : undefined,
    isHomeOwner,
    leadSource,
    rep,
    inviter: inviter ? omit(inviter, '__typename') : undefined,
    priorAddress: priorAddress ? omit(priorAddress, '__typename') : undefined,
    connectedHome: connectedHome ? omit(connectedHome, ['__typename']) : null,
    scheduledPersonalProperty:
      scheduledPersonalProperty?.items?.length && scheduledPersonalProperty?.deductible
        ? omit(scheduledPersonalProperty, ['__typename'])
        : null,
    home: homeCopy
      ? omit(homeCopy, [
          'county',
          'replacementCostEstimate',
          'highestEducation',
          'fpc',
          'wildfireHazardScore',
          'dtoName',
          'dtbowName',
          'dtbowLabel',
          '__typename'
        ])
      : undefined,
    homeCoverage: homeCoverage ? omit(homeCoverage, ['__typename']) : undefined,
    auto: auto ? omit(auto, ['__typename']) : undefined,
    autoCoverage: autoCoverage ? omit(autoCoverage, ['__typename']) : undefined,
    condo: condo
      ? omit(condo, [
          'county',
          'replacementCostEstimate',
          'highestEducation',
          'fpc',
          'wildfireHazardScore',
          'dtoName',
          'dtbowName',
          'dtbowLabel',
          '__typename'
        ])
      : undefined,
    condoCoverage: condoCoverage ? omit(condoCoverage, ['__typename']) : undefined,
    rentersCoverage: rentersCoverage ? omit(rentersCoverage, ['__typename', 'rentersLocation.__typename']) : undefined,
    cars: cars
      ? cars.map((car) =>
          omit(car, ['__typename', 'bodyType', 'year', 'model', 'make', 'garageLocation.__typename', 'addDate'])
        )
      : undefined,
    trailers: trailers
      ? trailers.map((trailer) =>
          omit(trailer, ['__typename', 'bodyType', 'model', 'make', 'garageLocation.__typename', 'addDate'])
        )
      : undefined,
    drivers: drivers
      ? drivers.map((driver) =>
          omit(driver, [
            '__typename',
            'firstName',
            'lastName',
            'middleName',
            'age',
            'schoolLocation.__typename',
            'schoolZip',
            'autoViolationsForUW',
            'autoViolations',
            'autoViolationDates',
            'addDate',
            'ratableIncidentCount'
          ])
        )
      : undefined,
    removedDrivers: removedDrivers || undefined,
    umbrellaCoverage: umbrellaCoverage
      ? omit(
          {
            ...umbrellaCoverage,
            rentalPropertyAddresses: umbrellaCoverage.rentalPropertyAddresses
              ? umbrellaCoverage.rentalPropertyAddresses.map((x) => omit(x, ['__typename']))
              : null,
            otherPropertyAddresses: umbrellaCoverage.otherPropertyAddresses
              ? umbrellaCoverage.otherPropertyAddresses.map((x) => omit(x, ['__typename']))
              : null
          },
          ['__typename']
        )
      : undefined,
    global: global
      ? {
          ...omit(global, [
            '__typename',
            'receivedCVData',
            'hadInforceCVPolicy',
            'rateControlDate',
            'priorIndividualBILimit',
            'priorOccurrenceBILimit',
            'priorUmIndividualBodilyInjury',
            'priorUmOccurrenceBodilyInjury',
            'priorDrivers',
            'priorCars',
            'preBindUWCheck'
          ])
        }
      : undefined,
    correctedAddress,
    preBindUWCheck: preBindUWCheck
      ? {
          ...omit(preBindUWCheck, ['__typename']),
          homeVerification: preBindUWCheck.homeVerification
            ? omit(preBindUWCheck.homeVerification, ['__typename'])
            : undefined,
          autoVerification: preBindUWCheck.autoVerification
            ? omit(preBindUWCheck.autoVerification, ['__typename'])
            : undefined
        }
      : undefined,
    overrides: omit(overrides, ['__typename'])
  };
};
export const shouldReject = ({ offerings }) => {
  return (
    (offerings.autoRejectCode && offerings.homeownersRejectCode) ||
    (offerings.autoRejectCode && offerings.condoRejectCode) ||
    (!offerings.offerAuto && !offerings.offerHomeowners) ||
    (!offerings.offerAuto && !offerings.offerCondo) ||
    (!offerings.offerAuto && offerings.homeownersRejectCode) ||
    (!offerings.offerHomeowners && offerings.autoRejectCode) ||
    (!offerings.offerRenters && offerings.rentersRejectCode) ||
    (!offerings.offerCondo && offerings.condoRejectCode)
  );
};

export const hasRejection = ({ offerings }) => {
  return (
    offerings.autoRejectCode ||
    offerings.homeownersRejectCode ||
    offerings.condoRejectCode ||
    offerings.monolineAutoRejectCode ||
    offerings.monolineHomeRejectCode ||
    offerings.monolineCondoRejectCode ||
    (!offerings.offerAuto && !offerings.offerHomeowners) ||
    (!offerings.offerAuto && !offerings.offerCondo)
  );
};
export const formattedError = (err) => {
  const lambdaUnhandledMessage = '{}';
  const message = err?.graphQLErrors?.[0]?.message || err?.message;
  try {
    if (message === lambdaUnhandledMessage) {
      return { message: 'Unhandled Lambda Error', code: 0 };
    }
    const parsed = JSON.parse(message);
    if (parsed) {
      if (parsed.type?.includes('Stripe')) {
        return {
          code: 0,
          message: `Error from Stripe: ${parsed.raw?.message || ''} ${parsed.raw?.decline_code || ''}`
        };
      }
      if (!parsed.code && parsed.errorMessage) {
        const parsedMessage = JSON.parse(parsed.errorMessage);
        return parsedMessage;
      }
      return parsed;
    }
  } catch (ex) {
    if (message) {
      return { message, code: 0 };
    }
    return { message: err, code: 0 };
  }
};
export const checkIfUnauthorizedAndPushToSearchPage = (error, history) => {
  if (error.message.includes('Not Authorized')) {
    // eslint-disable-next-line no-console
    console.log(error.message, 'pushing back to home');
    history.push('/search/offers');
  }
};

export const hasCoverageEligible = (coverage) => {
  if (coverage.length > 1) {
    return Number(coverage[0]) >= 250 && Number(coverage[1]) >= 500;
  }
  // CSL
  return Number(coverage[0]) >= 500;
};

export const canHaveExcessUM = ({ policyLimitUMBI, policyLimitUIMBI, state }) => {
  if (!policyLimitUMBI || !policyLimitUIMBI) {
    return false;
  }
  // must be 250/500 or 500CSL & in bix state

  const limitUMBI = policyLimitUMBI.includes('/') ? policyLimitUMBI.split('/') : policyLimitUMBI.split(' ').slice(0, 1);
  const limitUIMBI = policyLimitUIMBI.includes('/')
    ? policyLimitUIMBI.split('/')
    : policyLimitUIMBI.split(' ').slice(0, 1);
  return combinedUMUIM[state]
    ? hasCoverageEligible(limitUMBI)
    : hasCoverageEligible(limitUMBI) && hasCoverageEligible(limitUIMBI);
};

export const canAddUmbrellaUMUIM = ({ policyLimitUMBI, policyLimitUIMBI, state }) => {
  if (state === 'NH') {
    // Everything all the time in New Hampshire!
    return true;
  }
  return canHaveExcessUM({ policyLimitUMBI, policyLimitUIMBI, state });
};

export const canAddUmbrella = ({ policyLimitBIPD = '/', coverageX, drivers }) => {
  const isCombinedSingleLimit = policyLimitBIPD.includes('CSL');
  const limitBI = isCombinedSingleLimit ? policyLimitBIPD.split(' ')[0] : policyLimitBIPD.split('/')[1];
  return (
    (policyLimitBIPD === '/' || parseInt(limitBI, 10) >= 500) &&
    coverageX >= 300000 &&
    (!drivers || !drivers?.some((d) => d.excludeDriver))
  );
};

export const checkAdditionalCoverages = (offer, policyType) => {
  if (!offer || !policyType) {
    return { canAddEarthquake: false, canAddFlood: false };
  }
  const hasHome = policyType === policyTypes.Home || policyType === policyTypes.HABundle;
  const state = offer?.quote?.correctedAddress?.state;
  const canAddEarthquake = hasHome && earthquakeStates.includes(state);
  const canAddFlood = hasHome && floodStates.includes(state);
  return { canAddEarthquake, canAddFlood };
};
