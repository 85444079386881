export default (theme) => ({
  container: {
    padding: '7px 30px 25px 30px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  holdPaymentContainer: {
    flexGrow: 1,
    marginBottom: 18
  },
  label: {
    lineHeight: '14px',
    marginBottom: 4,
    display: 'block'
  },
  submit: {
    width: 168,
    marginRight: 24
  },
  dateComponent: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    marginRight: 32,
    padding: '0 8px'
  },
  right: {
    marginTop: 18
  },
  closeButton: {
    height: 42
  },
  rowTitle: {
    padding: '5px 0'
  },
  notificationCardBody: {
    marginLeft: 10
  }
});
