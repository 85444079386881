import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

import { ActionButton } from '../action-button';
import closeIcon from '../../assets/svg/x.svg';
import useStyles from './base-dialog.styles';

const BaseDialog = ({ children, size, open, onClose, title, blockContent, className, ...props }) => {
  const classes = useStyles();
  return (
    <Dialog
      maxWidth={size}
      open={open}
      onClose={onClose}
      classes={{
        paper: classNames(classes.container, className)
      }}
      {...props}
    >
      {title && (
        <DialogTitle>
          {onClose && (
            <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
          )}
          {title}
        </DialogTitle>
      )}
      <DialogContent className={blockContent ? classes.blockContent : classes.mContent}>
        {!title && onClose && (
          <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
        )}
        {children}
      </DialogContent>
    </Dialog>
  );
};

BaseDialog.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['xs', 'md', 'sm', 'lg', 'xl']),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  blockContent: PropTypes.bool
};

BaseDialog.defaultProps = {
  size: 'lg',
  onClose: undefined,
  title: '',
  className: '',
  blockContent: false
};

export default BaseDialog;
