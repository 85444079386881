import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.colorPalette.green_75
  },
  card: {
    marginBottom: theme.spacing(4)
  },
  buttonContainer: {
    cursor: 'pointer',
    margin: 'auto 0'
  }
}));

export default useStyles;
