import React from 'react';

import { Card } from 'core/components/card';
import { PersonsSection } from '../components/persons-section';
import { PersonCheckbox } from '../components/person-checkbox';

const SelectQHC = ({ context, onUpdate }) => {
  return (
    <>
      <PersonsSection title="Drivers">
        <Card>
          {context.data.drivers.map((driver, index) => (
            <PersonCheckbox
              key={driver.id}
              name={`drivers[${index}].pipHasQHC`}
              person={driver}
              value={driver.pipHasQHC}
              onChange={(checked) => {
                const updatedDriversList = [...context.data.drivers];
                updatedDriversList[index].pipHasQHC = checked;
                onUpdate({ drivers: updatedDriversList });
              }}
            />
          ))}
        </Card>
      </PersonsSection>
      {context.data.nonDrivers.length > 0 && (
        <PersonsSection title="Household members">
          <Card>
            {context.data.nonDrivers.map((resident, index) => (
              <PersonCheckbox
                key={resident.id}
                name={`nonDrivers[${index}].pipHasQHC`}
                person={resident}
                value={resident.pipHasQHC}
                onChange={(checked) => {
                  const updatedNonDriversList = [...context.data.nonDrivers];
                  updatedNonDriversList[index].pipHasQHC = checked;
                  onUpdate({ nonDrivers: updatedNonDriversList });
                }}
              />
            ))}
          </Card>
        </PersonsSection>
      )}
    </>
  );
};

export default SelectQHC;
