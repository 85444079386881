import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    backgroundColor: theme.colors.card_background_secondary
  },
  connectedCard: {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(16),
    padding: 32
  },
  valueField: {
    marginBottom: 0,
    '& > span': {
      marginTop: 0
    }
  },
  red: {
    '& #value': {
      color: `${theme.colorPalette.red_20}`
    }
  },
  notificationIcon: {
    display: 'block',
    height: 16,
    width: 16
  },
  equityWarning: {
    width: 155,
    height: 24,
    fontSize: 10,
    color: theme.colorPalette.beige_10,
    display: 'flex',
    alignItems: 'center',
    gap: 4
  }
}));

export default useStyles;
