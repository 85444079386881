import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'core/components/checkbox';
import { PersonalDetails } from '../../personal-details';
import { Row } from '../row';
import useStyles from './person-checkbox.styles';

const PersonCheckbox = ({ name, onChange, person, value, children }) => {
  const classes = useStyles();

  return (
    <Row>
      <Checkbox
        id={name}
        className={classes.checkbox}
        label={
          <div className={classes.name}>
            <PersonalDetails person={person} />
          </div>
        }
        onChange={(_, checked) => onChange(checked)}
        value={value}
        mode="dark"
      />
      {children}
    </Row>
  );
};

PersonCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  person: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default PersonCheckbox;
