import { useMemo } from 'react';

import useOptions from 'core/hooks/use-options';

const useAttendanceCareOptions = (context) => {
  const pipACROptions = useOptions('policyLimitPIPACR');

  return useMemo(() => {
    if (['OPTOUT', 'UNLIMITED'].includes(context.data.policyLimitPIPME)) {
      return pipACROptions.filter((option) => option.id === 'NONE');
    }
    return pipACROptions;
  }, [context.data.policyLimitPIPME]);
};

export default useAttendanceCareOptions;
