import React from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';

import { tooltipHoverTexts } from 'core/helpers/constants';
import { Tooltip } from 'core/components/tooltip';
import { Label } from 'core/components/label';
import useStyles from './residents-values.styles';

const ResidentValue = ({ label, value, name }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.residentInformation}>
      <Label type="infoValue" className={classes.value}>
        {value || 0}
      </Label>
      <Label type="infoLabel" className={classes.label}>
        {label}
      </Label>
      {tooltipHoverTexts[name] && <Tooltip text={tooltipHoverTexts[name]} label="More Info" placement="bottom" />}
    </Grid>
  );
};

const ResidentsValues = () => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <Grid container className={classes.residents}>
      <ResidentValue
        name="pipAllResidents"
        label="Residents living in household"
        value={values?.auto?.pipAllResidents}
      />
      <ResidentValue
        name="pipResidentsWithQHC"
        label="Residents with other QHC"
        value={values?.auto?.pipResidentsWithQHC}
      />
      <ResidentValue
        name="pipAdditionalResidents"
        label="Residents with PIP from other auto insurance"
        value={values?.auto?.pipAdditionalResidents}
      />
      <ResidentValue
        name="pipExcludedResidents"
        label="Residents excluded"
        value={values?.auto?.pipExcludedResidents}
      />
    </Grid>
  );
};

export default ResidentsValues;
