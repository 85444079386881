import React from 'react';
import { useFormikContext } from 'formik';
import {
  lookupsJson,
  getOptions,
  mineSubsidenceOptional,
  coverageFOffered,
  waterBackupNameByState,
  coverageRSExtendedForcedStates,
  coverageRSForcedStates,
  equipmentBreakdownServiceLineCoveragesStates,
  coverageCANotOffered,
  coverageDRNotOffered,
  coverageMINotOffered,
  coverageSTNotOffered,
  coverageSPNotOffered,
  coverageFairRentalNotOffered,
  coverageYardAndGardenNotOffered,
  coverageRSLabelStates,
  coverageRSDescriptionStates,
  coverageHomeownerProtectionNotOffered,
  noShowRoofSurfacesExtendedUnderXYearsStates,
  coverageRSForcedAtXYearsStates,
  EBSLstateDate
} from '@ourbranch/lookups';
import { isAfter, isSameDay } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { getShowMSALE, getShowDayCare, getShowSinkhole, getShowOilStorageTank } from 'offer/helpers/get-coverage';
import { tooltipHoverTexts } from 'core/helpers/constants';
import { useStore } from 'core/store';
import Field from 'core/components/form/form.v2';
import { Label } from 'core/components/label';
import { LabelTooltip } from 'core/components/label-tooltip';
import { awsDateToDateObject } from 'core/helpers/formatters';
import { localToUtcTime } from 'customer/components/policy/dates';
import useStyles from '../coverages.styles';

function OtherCoverages({ id, home, enforceMS, state, coverageA, fromPolicy }) {
  const classes = useStyles();
  const { values } = useFormikContext();
  const optionalMS =
    mineSubsidenceOptional[state] &&
    (mineSubsidenceOptional[state][home.county.toUpperCase()] || mineSubsidenceOptional[state].all);

  const offerF = coverageFOffered[state];
  const showCoverageCA = !coverageCANotOffered[state];
  const showCoverageDR = !coverageDRNotOffered[state];
  const showCoverageMI = !coverageMINotOffered[state];
  const showCoverageST = !coverageSTNotOffered[state];
  const showCoverageSP = !coverageSPNotOffered[state];
  const showFairRental = !coverageFairRentalNotOffered[state];
  const showYardAndGarden = !coverageYardAndGardenNotOffered[state];
  const showHomeownerProtection = !coverageHomeownerProtectionNotOffered[state];
  const showMSALE = getShowMSALE(state);
  const showDayCare = getShowDayCare(state);
  const showSinkhole = getShowSinkhole(state);
  const showOilStorageTank = getShowOilStorageTank(state);
  const waterBackupLabel = (waterBackupNameByState && waterBackupNameByState[state]) || 'Water Backup';
  const today = new Date().getFullYear();
  const roofAge = today - home.roofYear;

  const { account, offer: offerStore } = useStore();
  const policyStore = account?.policies?.policy;
  const offerWaterBackup =
    policyStore?.policy?.offer?.quote?.offerings?.offerWaterBackup ||
    offerStore?.offer?.quote?.offerings?.offerWaterBackup;

  let canAddEquipmentBreakdownServiceLineCoverages = equipmentBreakdownServiceLineCoveragesStates.includes(state);
  const rateControlDate = awsDateToDateObject(offerStore?.offer?.quote?.global.rateControlDate);
  const StateDate = new Date(EBSLstateDate[state]);

  let forcedRSECoverage = coverageRSForcedAtXYearsStates[state] && roofAge >= coverageRSForcedAtXYearsStates[state];

  if (fromPolicy) {
    const { updatedDateTime } = policyStore?.policy.versionHistory.find(({ version }) => version === 1);
    let createdDate = localToUtcTime(updatedDateTime, state);
    if (state === 'TX') {
      // exception in TX to look at effectiveDate because of early publishing
      createdDate = localToUtcTime(policyStore?.policy.effectiveDate, state);
    }
    canAddEquipmentBreakdownServiceLineCoverages =
      canAddEquipmentBreakdownServiceLineCoverages &&
      policyStore?.policy?.isBix &&
      (isAfter(createdDate, StateDate) || isSameDay(StateDate, createdDate));

    forcedRSECoverage = forcedRSECoverage && !policyStore.segment.homeCoverage.coverageRSExtended;
  } else {
    // new offer
    canAddEquipmentBreakdownServiceLineCoverages =
      canAddEquipmentBreakdownServiceLineCoverages &&
      offerStore?.offer?.quote?.isBix &&
      (isAfter(rateControlDate, StateDate) || isSameDay(StateDate, rateControlDate));
  }

  const isRSECoverageDisabled =
    coverageRSExtendedForcedStates[state] ||
    coverageRSForcedStates[state] ||
    (noShowRoofSurfacesExtendedUnderXYearsStates[state] &&
      roofAge < noShowRoofSurfacesExtendedUnderXYearsStates[state]) ||
    forcedRSECoverage;

  return (
    <Grid container item spacing={5} className={classes.coverageContainer} alignItems="flex-start">
      <Grid item xs={12}>
        <Label key="lblTop" type="formSubTitle">
          Other Coverages
        </Label>
      </Grid>
      <Field
        id={`${id}.coverageBP`}
        name={`${id}.coverageBP`}
        type="select"
        label="Business Property"
        mode="dark"
        options={getOptions('coverageBP', state)}
        xs={3}
      />
      {offerF && (
        <Field
          id={`${id}.coverageF`}
          name={`${id}.coverageF`}
          type="select"
          label="Fire Department Charges"
          mode="dark"
          options={getOptions('coverageF', state)}
          xs={3}
        />
      )}
      <Grid item xs={3}>
        <LabelTooltip
          label="Loss Assessments"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.lossAssessments }}
        >
          <Field
            id={`${id}.coverageG`}
            name={`${id}.coverageG`}
            type="select"
            mode="dark"
            options={getOptions('coverageG', state)}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={3} className={classes.noPaddingTop}>
        <LabelTooltip
          label="Building Code Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.buildingCodeCoverage }}
        >
          <Field
            id={`${id}.coverageBCPctOfA`}
            name={`${id}.coverageBCPctOfA`}
            type="select"
            mode="dark"
            // how do we change this over to lookupsJson?
            options={getOptions('coverageBCPctOfA', state).map((option) => ({
              id: option.id,
              value: `${option.value}% (${Number((option.value / 100) * coverageA).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
              })})`
            }))}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label={waterBackupLabel}
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.waterBackup }}
        >
          <Field
            id={`${id}.coverageWaterBackup`}
            name={`${id}.coverageWaterBackup`}
            type="select"
            mode="dark"
            className="has-custom-error"
            disabled={!offerWaterBackup}
            options={getOptions('waterBackupCoverageOptions', state)}
          />
          {!offerWaterBackup && (
            <Label type="formLabel" className={classes.errorMessage}>
              Cannot offer water backup due to history of water backup claims
            </Label>
          )}
        </LabelTooltip>
      </Grid>
      <Field
        id={`${id}.coverageJ`}
        name={`${id}.coverageJ`}
        type="select"
        label="Jewelry, Watches, and Furs"
        mode="dark"
        options={getOptions('coverageJ', state)}
        xs={3}
      />
      <Grid item xs={3} className={classes.noPaddingTop}>
        <LabelTooltip
          label="Increased Building Structure"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.increasedBuildingStructure }}
        >
          <Field
            id={`${id}.coverageIBSC`}
            name={`${id}.coverageIBSC`}
            type="select"
            mode="dark"
            options={getOptions('coverageIBSC', state)}
          />
        </LabelTooltip>
      </Grid>
      {showCoverageCA && (
        <Field
          id={`${id}.coverageCA`}
          name={`${id}.coverageCA`}
          type="select"
          label="Cameras"
          mode="dark"
          options={getOptions('coverageCA')}
          xs={3}
        />
      )}
      {showCoverageMI && (
        <Field
          id={`${id}.coverageMI`}
          name={`${id}.coverageMI`}
          type="select"
          label="Musical Instruments"
          mode="dark"
          xs={3}
          options={getOptions('coverageMI', state)}
        />
      )}
      {showCoverageST && (
        <Field
          id={`${id}.coverageST`}
          name={`${id}.coverageST`}
          type="select"
          label="Silverware Theft"
          mode="dark"
          xs={3}
          options={getOptions('coverageST', state)}
        />
      )}
      {showCoverageSP && (
        <Field
          id={`${id}.coverageSP`}
          name={`${id}.coverageSP`}
          type="select"
          label="Sports Equipment"
          mode="dark"
          xs={3}
          options={getOptions('coverageSP', state)}
        />
      )}
      {(optionalMS || enforceMS) && (
        <>
          <Field
            id={`${id}.coverageMS`}
            name={`${id}.coverageMS`}
            type="checkbox"
            label="Mine Subsidence"
            mode="dark"
            xs={6}
            disabled={!!enforceMS}
          />
          {showMSALE && !values[id].coverageMS && (
            <Field
              id={`${id}.coverageMSALE`}
              name={`${id}.coverageMSALE`}
              type="select"
              label="Mine Subsidence Additional Expense Coverage"
              mode="dark"
              xs={6}
              disabled
              options={lookupsJson.mineSubsidenceALECoverageOptions}
            />
          )}
          {showMSALE && values[id].coverageMS && (
            <Field
              id={`${id}.coverageMSALE`}
              name={`${id}.coverageMSALE`}
              type="select"
              label="Mine Subsidence Additional Expense Coverage"
              mode="dark"
              xs={6}
              options={lookupsJson.mineSubsidenceALECoverageOptions}
            />
          )}
        </>
      )}
      {showCoverageDR && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageDR`}
            name={`${id}.coverageDR`}
            type="checkbox"
            label="Electronic Data Recovery"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageDR}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      {showDayCare && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageDC`}
            name={`${id}.coverageDC`}
            type="checkbox"
            label="Day Care"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageDC}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <Field
          id={`${id}.coverageBuildingMaterialsTheft`}
          name={`${id}.coverageBuildingMaterialsTheft`}
          type="checkbox"
          label="Building Materials"
          mode="dark"
          tooltipText={tooltipHoverTexts.coverageBuildingMaterialsTheft}
          tooltipLabel="More Info"
        />
      </Grid>
      {showFairRental && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageFairRental`}
            name={`${id}.coverageFairRental`}
            type="checkbox"
            label="Fair Rental Income"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageFairRental}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      {showYardAndGarden && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageYardAndGarden`}
            name={`${id}.coverageYardAndGarden`}
            type="checkbox"
            label="Yard and Garden"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageYardAndGarden}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <Field
          id={`${id}.coverageExtendedPremises`}
          name={`${id}.coverageExtendedPremises`}
          type="checkbox"
          label="Extended Premises"
          mode="dark"
          tooltipText={tooltipHoverTexts.coverageExtendedPremises}
          tooltipLabel="More Info"
        />
      </Grid>
      {showSinkhole && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageSinkhole`}
            name={`${id}.coverageSinkhole`}
            type="checkbox"
            label="Sinkhole"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageSinkhole}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      {showOilStorageTank && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageOilStorageTank`}
            name={`${id}.coverageOilStorageTank`}
            type="checkbox"
            label="Oil Storage Tank"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageOilStorageTank}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      {showHomeownerProtection && (
        <Grid item xs={6}>
          <Field
            id={`${id}.homeownerProtection`}
            name={`${id}.homeownerProtection`}
            type="checkbox"
            label="Host Protection"
            mode="dark"
            tooltipText={tooltipHoverTexts.homeownerProtection}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <Field
          id={`${id}.coverageRSExtended`}
          name={`${id}.coverageRSExtended`}
          type="checkbox"
          label={coverageRSLabelStates[state] || 'Roof Surfaces Extended'}
          mode="dark"
          tooltipText={
            isRSECoverageDisabled
              ? 'Based on the age and material of the roof, this home is not eligible for Roof Surfaces Extended Coverage. When not selected, Branch will pay for a portion of approved roof claims according to a roof payment schedule.'
              : coverageRSDescriptionStates[state] || tooltipHoverTexts.coverageRSExtended
          }
          tooltipLabel="More Info"
          disabled={isRSECoverageDisabled}
        />
      </Grid>
      {canAddEquipmentBreakdownServiceLineCoverages && (
        <Grid item xs={6}>
          <Field
            id={`${id}.coverageEBSL`}
            name={`${id}.coverageEBSL`}
            type="checkbox"
            label="Equipment Breakdown & Service Line"
            mode="dark"
            tooltipText={tooltipHoverTexts.coverageEBSL}
            tooltipLabel="More Info"
          />
        </Grid>
      )}
    </Grid>
  );
}

OtherCoverages.propTypes = {
  id: PropTypes.string.isRequired,
  home: PropTypes.object.isRequired,
  enforceMS: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  coverageA: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fromPolicy: PropTypes.bool.isRequired
};

export default observer(OtherCoverages);
