import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    backgroundColor: theme.colors.card__background_primary
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(8, 6, 3)
  },
  stepDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  stepTitle: {
    color: theme.colors.green_15,
    fontWeight: 600
  },
  stepSubtitle: {
    color: theme.colors.green_15
  },
  content: {
    backgroundColor: theme.colors.card_background_primary_editable,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6)
  }
}));

export default useStyles;
