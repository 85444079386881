import { differenceInYears } from 'date-fns';

export const yesNoOptions = [
  {
    value: 'yes',
    label: 'Yes'
  },
  {
    value: 'no',
    label: 'No'
  }
];

export const yesNoIDontKnowOptions = [
  ...yesNoOptions,
  {
    value: 'idk',
    label: "I don't know"
  }
];

export const excludeEveryoneOptions = [
  {
    value: 'yes',
    label: 'Yes, exclude all'
  },
  {
    value: 'no',
    label: 'No, include all'
  }
];

export const getPersonAge = (dateOfBirth) => differenceInYears(new Date(), new Date(dateOfBirth));
