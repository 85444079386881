import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2, 0),
    backgroundColor: theme.colorPalette.green_30,
    color: theme.colors.light_text,
    justifyContent: 'center'
  },
  icon: {
    width: 16,
    height: 16
  }
});

export default makeStyles(styles);
