import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    backgroundColor: theme.colorPalette.white_30,
    flexDirection: 'column',
    gap: theme.spacing(8),
    padding: theme.spacing(8)
  },
  divider: {
    backgroundColor: theme.colorPalette.beige_10
  },
  button: {
    width: 'fit-content',
    alignSelf: 'center',
    fontSize: 16,
    padding: theme.spacing(0, 4)
  }
}));

export default useStyles;
