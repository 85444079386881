import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { NotificationCard } from 'core/components/notification-card';
import RemoveButton from 'core/components/remove-button';
import useStyles from '../coverages.styles';

const PurchaseOffSite = ({ name, partnerName, policyId, purchaseLink, policyLink, onViewPolicy }) => {
  const classes = useStyles();
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const onRemovePolicyId = useCallback(() => {
    setFieldValue(name, null);
    setFieldTouched(name);
  }, [name, setFieldValue, setFieldTouched]);

  return (
    <>
      {!policyId && (
        <NotificationCard>
          <Grid container alignItems="center" justify="space-between">
            <div>
              Pending purchase through<strong>&nbsp;{partnerName}&nbsp;</strong>
            </div>
            <Button className={classes.button}>
              <a className={classes.buttonLink} rel="noreferrer noopener" target="_blank" href={purchaseLink}>
                Purchase off site
              </a>
            </Button>
          </Grid>
        </NotificationCard>
      )}
      {policyId ? (
        <Grid container alignItems="center" className={classes.purchasedContainer}>
          <Grid item xs={5}>
            <Label type="coverageParagraph">
              Purchased through partner <strong>&nbsp;{partnerName}&nbsp;</strong>
            </Label>
          </Grid>
          <FormField name={name} type="value" label="Policy ID" xs={3} mode="dark" value={policyId} />
          <Grid item xs={4}>
            <Button className={classes.button} onClick={onViewPolicy}>
              {onViewPolicy ? (
                'View policy'
              ) : (
                <a
                  className={classes.buttonLink}
                  rel="noreferrer noopener"
                  target="_blank"
                  href={policyLink || purchaseLink}
                >
                  View policy
                </a>
              )}
            </Button>
            <RemoveButton mode="big" onClick={onRemovePolicyId} />
          </Grid>
        </Grid>
      ) : (
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Label type="coverageParagraph">Once purchased, enter the Policy ID to save it to this policy.</Label>
          </Grid>
          <FormField name={name} type="string" label="Policy ID" xs={4} mode="dark" />
        </Grid>
      )}
    </>
  );
};

PurchaseOffSite.propTypes = {
  partnerName: PropTypes.string.isRequired,
  purchaseLink: PropTypes.string.isRequired,
  policyId: PropTypes.string,
  policyLink: PropTypes.string,
  onViewPolicy: PropTypes.func
};

PurchaseOffSite.defaultProps = {
  policyId: null,
  policyLink: null,
  onViewPolicy: null
};

export default PurchaseOffSite;
