import { useMemo } from 'react';

import { HEALTH_INSURANCE_TYPE } from 'core/helpers/constants';
import useOptions from 'core/hooks/use-options';

const usePIPCoverageOptions = (context) => {
  const policyLimitPIPMEOptions = useOptions('policyLimitPIPME');
  const allResidents = [...context.data.drivers, ...context.data.nonDrivers];

  return useMemo(() => {
    if (allResidents.every((resident) => !resident.pipOptOut)) {
      if (
        [HEALTH_INSURANCE_TYPE.PERSONAL, HEALTH_INSURANCE_TYPE.MEDICARE].includes(context.data.pipHealthInsuranceType)
      ) {
        return policyLimitPIPMEOptions.filter((option) => !['OPTOUT', '50000'].includes(option.id));
      }
      return policyLimitPIPMEOptions.filter((option) => option.id !== 'OPTOUT');
    }

    if (
      allResidents.every((resident) => resident.pipOptOut) &&
      context.data.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE
    ) {
      return policyLimitPIPMEOptions.filter((option) => option.id === 'OPTOUT');
    }

    /* remaining cases:
     * Everyone is excluded and health insurance is not medicare
     * Some residents are excluded and health insurance is not medicare (As medicare forces you to either include or exclude everyone)
     */
    return policyLimitPIPMEOptions.filter((option) => option.id === '250000');
  }, [allResidents, context.data.pipHealthInsuranceType]);
};

export default usePIPCoverageOptions;
