import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';

import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';
import { ReactComponent as EqualsIcon } from 'core/assets/svg/equals-white.svg';
import { ReactComponent as MinusIcon } from 'core/assets/svg/minus.svg';
import { useStore } from 'core/store';

import useStyles from './price-summary.styles';

function PriceSummary({ premium, fees, surplus }) {
  const classes = useStyles();
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();

  const totalPrice = premium + fees + surplus;
  const totalRemaining = totalPrice - store.totalReceived;

  return (
    <Grid className={classes.wrapper} alignItems="center" justify="flex-start" container>
      <ValueField
        className={classes.valueField}
        label="Total Price"
        formatter={currencyFormatter}
        value={totalPrice}
        mode="dark"
      />
      <MinusIcon className={classes.icon} />
      <ValueField
        className={classes.valueField}
        label="Total Paid/Pending"
        formatter={currencyFormatter}
        value={store.totalReceived}
        mode="dark"
      />
      <EqualsIcon className={classes.icon} />
      <ValueField
        className={classes.valueField}
        label="Total Remaining"
        formatter={currencyFormatter}
        value={totalRemaining}
        mode="dark"
      />
    </Grid>
  );
}

PriceSummary.propTypes = {
  fees: PropTypes.number.isRequired,
  premium: PropTypes.number.isRequired,
  surplus: PropTypes.number.isRequired
};

export default PriceSummary;
