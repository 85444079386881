import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import { ActionButton } from 'core/components/action-button';
import useStyles from './no-reinstatement-modal.styles';

const NoReinstatementModal = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle>
        <Label className={classes.title}>Policy Ineligible for Reinstatement</Label>
      </DialogTitle>
      <DialogContent>
        <p className={classes.text}>
          This policy was canceled due to non-payment and is therefore ineligible to be reinstated. If you have
          questions about this, please reach out to your manager.
        </p>
      </DialogContent>
      <Grid container className={classes.buttonRow}>
        <Button variant="contained" color="primary" onClick={onClose} className={classes.button}>
          <Label className={classes.buttonLabel}>Go back</Label>
        </Button>
      </Grid>
    </Dialog>
  );
};
export default NoReinstatementModal;
