import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'core/components/button';
import useStyles from './modal-footer.styles';

const ModalFooter = ({ onPrevious, onNext, isLastStep, loading }) => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      {onPrevious && (
        <Button
          className={classes.button}
          onClick={onPrevious}
          variant="contained"
          color="secondary"
          disabled={loading}
        >
          Previous
        </Button>
      )}
      {onNext && (
        <Button className={classes.button} onClick={onNext} variant="contained" color="primary" disabled={loading}>
          {isLastStep ? 'Save and complete' : 'Next'}
        </Button>
      )}
    </div>
  );
};

ModalFooter.propTypes = {
  isLastStep: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func
};

ModalFooter.defaultProps = {
  loading: false,
  onPrevious: null,
  onNext: null
};

export default ModalFooter;
