import React from 'react';

import { Select } from 'core/components/select';
import useOptions from 'core/hooks/use-options';
import { RadioGroup } from '../../components/radio-group';
import { yesNoOptions } from '../../helpers';
import useStyles from './2-primary-applicant-insurance-type.styles';

const PrimaryApplicantInsuranceType = ({ context, onUpdate }) => {
  const classes = useStyles();
  const isRadioDisabled = context.data.drivers.length + context.data.nonDrivers.length === 1;
  const healthInsuranceTypes = useOptions('pipHealthInsuranceOptions');

  return (
    <>
      <Select
        type="select"
        className={classes.select}
        value={context.data.pipHealthInsuranceType}
        label="Primary applicant’s type of health insurance"
        options={healthInsuranceTypes}
        onChange={(_, value) => onUpdate({ pipHealthInsuranceType: value })}
        mode="light"
      />
      <RadioGroup
        name="pipEveryoneOnSamePlan"
        label="Is everyone in the household on the primary applicant’s health insurance plan?"
        onChange={onUpdate}
        value={context.data.pipEveryoneOnSamePlan}
        options={yesNoOptions}
        disabled={isRadioDisabled}
      />
    </>
  );
};

export default PrimaryApplicantInsuranceType;
