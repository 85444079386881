import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formTitle: {
    fontWeight: 400,
    gridColumn: '1 / span 3'
  },
  card: {
    marginBottom: theme.spacing(4)
  },
  container: {
    width: '100%',
    borderRadius: theme.sizes.roundCorners,
    border: `2px solid ${theme.colorPalette.white_10}`,
    padding: theme.spacing(6, 8, 8),
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: theme.spacing(3, 2)
  },
  addButton: {
    gridColumn: 3
  }
}));

export default useStyles;
