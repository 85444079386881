import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Divider } from '@material-ui/core';

import Label from 'core/components/label/label';
import { Button } from 'core/components/button';
import AlertIconWhite from 'core/assets/svg/alert-white.svg';
import LaunchIconWhite from 'core/assets/svg/launch-white.svg';

import { carrierMap } from '../helpers';
import styles from '../quinstreet.styles';

const numberDisplay = (number) =>
  Number.isInteger(number)
    ? number.toLocaleString('en-US')
    : number.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });

const IndividualQuinstreetOffer = ({ classes, quinstreetOffer }) => {
  return (
    <div className={classes.individualContainer}>
      <div className={classNames(classes.containerInner, classes.noBackground)}>
        <img
          src={
            carrierMap[
              quinstreetOffer.carrierName.toLowerCase() === 'clearcover'
                ? 'progressive'
                : quinstreetOffer.carrierName.toLowerCase()
            ].logo
          }
          className={classes.icon}
          alt="Banner Icon"
        />

        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => {
            window.open(
              quinstreetOffer.status === 'error'
                ? carrierMap[
                    quinstreetOffer.carrierName.toLowerCase() === 'clearcover'
                      ? 'progressive'
                      : quinstreetOffer.carrierName.toLowerCase()
                  ].url
                : quinstreetOffer?.bridgeUrl,
              '_blank'
            );
          }}
        >
          <img src={LaunchIconWhite} className={classes.buttonIcon} alt="Launch Icon" />
          Go to {quinstreetOffer.carrierName}
        </Button>
      </div>
      {quinstreetOffer.status === 'success' && (
        <div className={classNames(classes.containerInner, classes.noPadding)}>
          <Divider className={classes.divider} />
        </div>
      )}
      {quinstreetOffer.status === 'error' && (
        <div className={classNames(classes.containerInner, classes.noBackground)}>
          <div className={classes.errorContainer}>
            <img src={AlertIconWhite} className={classes.icon} alt="Banner Icon" />
            <Label>
              We could not generate a quote for this carrier. Please try directly from the carriers website via the
              link.
            </Label>
          </div>
        </div>
      )}

      {quinstreetOffer.status === 'success' && (
        <>
          <div className={classNames([classes.containerInner, classes.flexStart, classes.noBackground])}>
            {quinstreetOffer?.monthly && (
              <>
                <div className={classes.flex}>
                  <span className={classNames([classes.dollarIcon, classes.beige])}>$</span>
                  <h1 className={classes.header}>{numberDisplay(quinstreetOffer?.monthly)}</h1>
                  <div>
                    <span className={classes.smallText}>PAID MONTHLY</span>
                    <span className={classes.thinText}>/6 MOS</span>
                  </div>
                </div>
                <span className={classes.bigSlash}>/</span>
              </>
            )}
            <div className={classes.flex}>
              <span className={classes.dollarIcon}>$</span>
              <h1 className={classNames(classes.header, { [classes.beige]: quinstreetOffer?.monthly })}>
                {numberDisplay(quinstreetOffer?.premium)}
              </h1>
              <div>
                <span className={classes.smallText}>PAID IN FULL</span>
                <span className={classes.thinText}>/6 MOS</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

IndividualQuinstreetOffer.propTypes = {
  classes: PropTypes.object.isRequired,
  quinstreetOffer: PropTypes.object.isRequired
};

export default withStyles(styles)(IndividualQuinstreetOffer);
