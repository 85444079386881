import { assign, createMachine } from 'xstate';

import { HEALTH_INSURANCE_TYPE } from 'core/helpers/constants';
import { getPersonAge } from './helpers';

const saveHistory = (stateToSave) => (context, event) =>
  event.type === 'NEXT' ? [...context.history, stateToSave] : context.history;

const PersonalInjuryProtectionMachine = createMachine(
  {
    id: 'PipMachine',
    initial: 'step1_addNewHouseholdMembers',
    context: {
      history: [],
      data: {
        drivers: [],
        nonDrivers: [],
        pipHealthInsuranceType: '',
        pipEveryoneOnSamePlan: '',
        pipResidentsHaveQHC: '',
        pipPrimaryHasQHC: '',
        policyLimitPIPACR: '',
        policyLimitPIPME: '',
        excludeEveryone: ''
      }
    },
    states: {
      step1_addNewHouseholdMembers: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: {
            target: 'step2_primaryApplicantInsuranceType',
            actions: 'checkAndUpdateIfEveryoneOnSamePlan'
          }
        },
        exit: assign({
          history: saveHistory('step1_addNewHouseholdMembers')
        })
      },
      step2_primaryApplicantInsuranceType: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: [
            {
              target: 'step3A_indicateIfResidentsHaveQHC',
              cond: 'hasMedicaidOrPersonalAndFamilyDoesNot'
            },
            {
              target: 'step3B_primaryHasPersonal',
              cond: 'hasPersonalAndAllFamilyInPlan'
            },
            {
              target: 'step3C_primaryHasMedicare',
              cond: 'hasMedicareAndAllFamilyInPlan'
            },
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents'
            },
            {
              target: 'step8_choosePIPCoverage'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState'
          }
        },
        exit: assign({
          history: saveHistory('step2_primaryApplicantInsuranceType')
        })
      },
      step3A_indicateIfResidentsHaveQHC: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: [
            {
              target: 'step4_selectQHCResidents',
              cond: 'residentsHaveQHC'
            },
            {
              target: 'step5_residentsWithExternalPIP',
              cond: 'hasNonDriversWithoutQHC'
            },
            {
              target: 'step6A_excludeResidents',
              cond: 'hasResidentsWithQHCOrExternalPIP'
            },
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents'
            },
            {
              target: 'step8_choosePIPCoverage'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState'
          }
        },
        exit: assign({
          history: saveHistory('step3A_indicateIfResidentsHaveQHC')
        })
      },
      step3B_primaryHasPersonal: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: [
            {
              target: 'step3B_yesTransition',
              cond: 'primaryHasQHC',
              actions: 'setQHCForAllResidents'
            },
            {
              target: 'step5_residentsWithExternalPIP',
              cond: 'hasNonDriversWithoutQHC',
              actions: 'removeQHCForAllResidents'
            },
            {
              target: 'step6A_excludeResidents',
              cond: 'hasResidentsWithQHCOrExternalPIP',
              actions: 'removeQHCForAllResidents'
            },
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents',
              actions: 'removeQHCForAllResidents'
            },
            {
              target: 'step8_choosePIPCoverage',
              actions: 'removeQHCForAllResidents'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState',
            actions: 'removeQHCForAllResidents'
          }
        },
        exit: assign({
          history: saveHistory('step3B_primaryHasPersonal')
        })
      },
      step3B_yesTransition: {
        always: [
          {
            target: 'step6A_excludeResidents',
            cond: 'hasResidentsWithQHCOrExternalPIP'
          },
          {
            target: 'step7_waiveWorkLossCoverage',
            cond: 'hasSeniorNonExcludedResidents'
          },
          {
            target: 'step8_choosePIPCoverage'
          }
        ]
      },
      step3C_primaryHasMedicare: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: [
            {
              target: 'step6B_excludeEveryoneFromPIP',
              cond: 'primaryHasQHC',
              actions: 'setQHCForAllResidents'
            },
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents',
              actions: 'removeQHCForAllResidents'
            },
            {
              target: 'step8_choosePIPCoverage',
              actions: 'removeQHCForAllResidents'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState',
            actions: 'removeQHCForAllResidents'
          }
        },
        exit: assign({
          history: saveHistory('step3C_primaryHasMedicare')
        })
      },
      step4_selectQHCResidents: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: [
            {
              target: 'step5_residentsWithExternalPIP',
              cond: 'hasNonDriversWithoutQHC'
            },
            {
              target: 'step6A_excludeResidents',
              cond: 'hasResidentsWithQHCOrExternalPIP'
            },
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents'
            },
            {
              target: 'step8_choosePIPCoverage'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState',
            actions: 'removeQHCForAllResidents'
          }
        },
        exit: assign({
          history: saveHistory('step4_selectQHCResidents')
        })
      },
      step5_residentsWithExternalPIP: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: [
            {
              target: 'step6A_excludeResidents',
              cond: 'hasResidentsWithQHCOrExternalPIP'
            },
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents'
            },
            {
              target: 'step8_choosePIPCoverage'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState',
            actions: 'removeExternalPIPForAllResidents'
          }
        },
        exit: assign({
          history: saveHistory('step5_residentsWithExternalPIP')
        })
      },
      step6A_excludeResidents: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: [
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents'
            },
            {
              target: 'step8_choosePIPCoverage'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState',
            actions: 'removeExcludeEveryoneFromPIP'
          }
        },
        exit: assign({
          history: saveHistory('step6A_excludeResidents')
        })
      },
      step6B_excludeEveryoneFromPIP: {
        on: {
          UPDATE: {
            actions: ['updateData', 'setExcludeEveryoneFromPIP']
          },
          NEXT: [
            {
              target: 'step7_waiveWorkLossCoverage',
              cond: 'hasSeniorNonExcludedResidents'
            },
            {
              target: 'step8_choosePIPCoverage'
            }
          ],
          PREVIOUS: {
            target: 'previousTransitionState',
            actions: 'removeExcludeEveryoneFromPIP'
          }
        },
        exit: assign({
          history: saveHistory('step6B_excludeEveryoneFromPIP')
        })
      },
      step7_waiveWorkLossCoverage: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: {
            target: 'step8_choosePIPCoverage'
          },
          PREVIOUS: {
            target: 'previousTransitionState',
            actions: 'resetWaivedWorkLossCoverage'
          }
        },
        exit: assign({
          history: saveHistory('step7_waiveWorkLossCoverage')
        })
      },
      step8_choosePIPCoverage: {
        on: {
          UPDATE: {
            actions: 'updateData'
          },
          NEXT: {
            target: 'completed'
          },
          PREVIOUS: {
            target: 'previousTransitionState'
          }
        }
      },
      previousTransitionState: {
        always: [
          {
            target: 'step1_addNewHouseholdMembers',
            cond: { type: 'isPreviousState', state: 'step1_addNewHouseholdMembers' }
          },
          {
            target: 'step2_primaryApplicantInsuranceType',
            cond: { type: 'isPreviousState', state: 'step2_primaryApplicantInsuranceType' }
          },
          {
            target: 'step3A_indicateIfResidentsHaveQHC',
            cond: { type: 'isPreviousState', state: 'step3A_indicateIfResidentsHaveQHC' }
          },
          {
            target: 'step3B_primaryHasPersonal',
            cond: { type: 'isPreviousState', state: 'step3B_primaryHasPersonal' },
            actions: 'removeQHCForAllResidents'
          },
          {
            target: 'step3C_primaryHasMedicare',
            cond: { type: 'isPreviousState', state: 'step3C_primaryHasMedicare' }
          },
          {
            target: 'step4_selectQHCResidents',
            cond: { type: 'isPreviousState', state: 'step4_selectQHCResidents' }
          },
          {
            target: 'step5_residentsWithExternalPIP',
            cond: { type: 'isPreviousState', state: 'step5_residentsWithExternalPIP' }
          },
          {
            target: 'step6A_excludeResidents',
            cond: { type: 'isPreviousState', state: 'step6A_excludeResidents' }
          },
          {
            target: 'step6B_excludeEveryoneFromPIP',
            cond: { type: 'isPreviousState', state: 'step6B_excludeEveryoneFromPIP' }
          },
          {
            target: 'step7_waiveWorkLossCoverage',
            cond: { type: 'isPreviousState', state: 'step7_waiveWorkLossCoverage' }
          }
        ],
        exit: assign({
          history: (context) => context.history.slice(0, -1)
        })
      },
      completed: {
        type: 'final'
      }
    },
    predictableActionArguments: true,
    preserveActionOrder: true
  },
  {
    guards: {
      isPreviousState: ({ history }, event, { cond }) => {
        const { state } = cond;
        const previous = history[history.length - 1];
        return previous === state;
      },
      hasMedicaidOrPersonalAndFamilyDoesNot: (ctx) =>
        ctx.data.pipEveryoneOnSamePlan === 'no' &&
        [HEALTH_INSURANCE_TYPE.MEDICAID, HEALTH_INSURANCE_TYPE.PERSONAL].includes(ctx.data.pipHealthInsuranceType),
      hasPersonalAndAllFamilyInPlan: (ctx) =>
        ctx.data.pipEveryoneOnSamePlan === 'yes' && ctx.data.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.PERSONAL,
      hasMedicareAndAllFamilyInPlan: (ctx) =>
        ctx.data.pipEveryoneOnSamePlan === 'yes' && ctx.data.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE,
      residentsHaveQHC: (ctx) => ctx.data.pipResidentsHaveQHC === 'yes',
      hasNonDriversWithoutQHC: (ctx) => ctx.data.nonDrivers.some((resident) => !resident.pipHasQHC),
      hasResidentsWithQHCOrExternalPIP: (ctx) =>
        ctx.data.drivers.some((driver) => driver.pipHasQHC) ||
        ctx.data.nonDrivers.some((resident) => resident.pipHasQHC || !resident.pipEligible),
      hasSeniorNonExcludedResidents: (ctx) =>
        ctx.data.excludeEveryone !== 'yes' &&
        [...ctx.data.drivers, ...ctx.data.nonDrivers].some((r) => getPersonAge(r.dateOfBirth) >= 60 && !r.pipOptOut),
      primaryHasQHC: (ctx) => ctx.data.pipPrimaryHasQHC === 'yes'
    },
    actions: {
      updateData: assign({
        data: (ctx, event) => ({ ...ctx.data, ...event.payload })
      }),
      setQHCForAllResidents: assign({
        data: (ctx) => ({
          ...ctx.data,
          nonDrivers: ctx.data.nonDrivers.map((r) => ({ ...r, pipHasQHC: true })),
          drivers: ctx.data.drivers.map((r) => ({ ...r, pipHasQHC: true }))
        })
      }),
      removeQHCForAllResidents: assign({
        data: (ctx) => ({
          ...ctx.data,
          nonDrivers: ctx.data.nonDrivers.map((r) => ({ ...r, pipHasQHC: false })),
          drivers: ctx.data.drivers.map((r) => ({ ...r, pipHasQHC: false }))
        })
      }),
      checkAndUpdateIfEveryoneOnSamePlan: assign({
        data: (ctx) => ({
          ...ctx.data,
          pipEveryoneOnSamePlan:
            ctx.data.drivers.length + ctx.data.nonDrivers.length === 1 ? 'yes' : ctx.data.pipEveryoneOnSamePlan
        })
      }),
      setExcludeEveryoneFromPIP: assign({
        data: (ctx) => ({
          ...ctx.data,
          nonDrivers: ctx.data.nonDrivers.map((r) => ({ ...r, pipOptOut: ctx.data.excludeEveryone === 'yes' })),
          drivers: ctx.data.drivers.map((r) => ({ ...r, pipOptOut: ctx.data.excludeEveryone === 'yes' }))
        })
      }),
      removeExcludeEveryoneFromPIP: assign({
        data: (ctx) => ({
          ...ctx.data,
          nonDrivers: ctx.data.nonDrivers.map((r) => ({ ...r, pipOptOut: false })),
          drivers: ctx.data.drivers.map((r) => ({ ...r, pipOptOut: false })),
          excludeEveryone: 'no'
        })
      }),
      removeExternalPIPForAllResidents: assign({
        data: (ctx) => ({
          ...ctx.data,
          nonDrivers: ctx.data.nonDrivers.map((r) => ({ ...r, pipEligible: true }))
        })
      }),
      resetWaivedWorkLossCoverage: assign({
        data: (ctx) => ({
          ...ctx.data,
          nonDrivers: ctx.data.nonDrivers.map((r) => ({ ...r, waivedPIPWL: false })),
          drivers: ctx.data.drivers.map((r) => ({ ...r, waivedPIPWL: false }))
        })
      })
    }
  }
);

export default PersonalInjuryProtectionMachine;
