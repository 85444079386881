import React from 'react';

import { Card } from 'core/components/card';
import notificationIconWhite from './alert-small-white.svg';
import useStyles from './future-effective-date-warning.styles';

const FutureEffectiveDateWarning = () => {
  const classes = useStyles();
  return (
    <Card className={classes.warning}>
      <img src={notificationIconWhite} alt="Notification Icon" />
      All policies must be at least 7 days future effective.
    </Card>
  );
};

export default FutureEffectiveDateWarning;
