import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  residentsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(-4) // to cancel the margin of the section title
  },
  noExcludedResidentsCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(8),
    alignItems: 'center'
  },
  personCard: {
    padding: theme.spacing(2, 4)
  },
  status: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center'
  },
  tooltip: {
    display: 'flex'
  }
}));

export default useStyles;
