import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import uuidv4 from 'uuid/v4';

import { Button } from 'core/components/button';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import withDatePicker from 'core/components/with-date-picker';
import useStyles from './new-household-member-form.styles';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  dateOfBirth: Yup.string().required('Date of birth is required')
});

const NewHouseholdMemberForm = ({ onAdd }) => {
  const classes = useStyles();

  const addNewHouseholdMember = (values, formik) => {
    onAdd({ id: uuidv4(), ...values });
    formik.resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        dateOfBirth: new Date().toISOString(),
        pipEligible: true,
        pipOptOut: false,
        pipHasQHC: false,
        waivedPIPWL: false
      }}
      validateOnChange={false}
      onSubmit={addNewHouseholdMember}
      validationSchema={validationSchema}
    >
      <Form className={classes.container}>
        <Label type="infoSubtitle" className={classes.formTitle}>
          Add household member
        </Label>
        <FormField type="string" id="firstName" name="firstName" label="First Name" mode="light" />
        <FormField type="string" id="lastName" name="lastName" label="Last Name" mode="light" />
        <FormField type="date" id="dateOfBirth" name="dateOfBirth" label="Date Of Birth" mode="light" />
        <Button type="submit" variant="contained" color="secondary" className={classes.addButton}>
          Add household member
        </Button>
      </Form>
    </Formik>
  );
};

NewHouseholdMemberForm.propTypes = {
  onAdd: PropTypes.func.isRequired
};

export default withDatePicker(NewHouseholdMemberForm);
