import React from 'react';

import { RadioGroup } from '../components/radio-group';
import { yesNoIDontKnowOptions } from '../helpers';

const PrimaryHasQHC = ({ context, onUpdate }) => {
  return (
    <RadioGroup
      name="pipPrimaryHasQHC"
      label="Does the primary applicant’s health insurer cover medical expenses resulting from a motor vehicle accident and have an annual deductible of $6,000 or less?"
      options={yesNoIDontKnowOptions}
      value={context.data.pipPrimaryHasQHC}
      onChange={onUpdate}
    />
  );
};

export default PrimaryHasQHC;
