import ProgressiveLogo from 'core/assets/svg/progressive-logo.svg';
import BristolWestLogo from 'core/assets/svg/bristol-west-logo.svg';
import DairylandLogo from 'core/assets/svg/dairyland-logo.svg';

export const buildQuinstreetQuoteFromPolicy = ({ policy, fname, lname, list }) => {
  return {
    id: policy.id,
    webUserId: policy.webUserId,
    quote: {
      fname,
      lname,
      cars: policy.policyDetails.cars,
      drivers: policy.policyDetails.drivers,
      monthsAtCurrentAddress: 50, // placeholder for now
      home: policy.offer.quote.home,
      autoCoverage: policy.policyDetails.autoCoverage,
      correctedAddress: policy.offer.quote.correctedAddress,
      global: {
        currentlyInsured: true,
        currentAutoCarrier: 'Branch Insurance',
        continuousAutoCoverageStartDate: list.find((policy) => policy.id.includes('auto-001')).effectiveDate,
        priorIndividualBILimit: parseInt(policy.policyDetails.autoCoverage.policyLimitBIPD.split('/')[0], 10) * 1000,
        priorOccurrenceBILimit: parseInt(policy.policyDetails.autoCoverage.policyLimitBIPD.split('/')[1], 10) * 1000
      }
    }
  };
};

export const carrierMap = {
  progressive: {
    logo: ProgressiveLogo,
    url: 'https://www.foragentsonly.com/login/'
  },
  'bristol west': {
    logo: BristolWestLogo,
    url: 'https://www.iaproducers.com'
  },
  dairyland: {
    logo: DairylandLogo,
    url: 'https://agent.dairylandagent.com/'
  }
};
