import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getOptions } from '@ourbranch/lookups';
import { connect, Form } from 'formik';
import { getSnapshot } from 'mobx-state-tree';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { withStore } from 'core/store';
import { useErrors } from '../../helpers/error-hooks';
import { Collapsible } from '../collapsible';
import { Errors } from '../errors';
import { autoDetailsCode } from '../quote-forms/quote-forms.constants';
import styles from './auto-details.styles';

const errorsCondition = (code) => autoDetailsCode.includes(code);

const AutoDetails = ({ classes, formik: { values }, store, sectionsToShow }) => {
  const { state } = values;
  const {
    quote: { errors: quoteErrors }
  } = store;
  const limitOptions = useMemo(() => {
    return getOptions('policyLimitBIPD', state); // no 500 CSL as of 5/18/2023
  }, [state]);
  const yearsOptions = useMemo(() => {
    return getOptions('numberOfPriorYearsCoverage', state);
  }, [state]);
  const errors = useErrors(getSnapshot(quoteErrors), errorsCondition);
  const autoErrors = useRef(null);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (errors.length && autoErrors.current) {
      autoErrors.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (sectionsToShow && sectionsToShow?.length > 0) {
      const shouldBeShown = autoDetailsCode.every((element) => sectionsToShow.includes(element));
      setShouldShow(shouldBeShown);
    }
  }, [sectionsToShow]);

  return (
    <Form>
      {(errors.length > 0 || shouldShow) && (
        <>
          <Errors errors={errors} ref={autoErrors} />
          <Collapsible title="Current Auto Details">
            <>
              <Grid item xs={6}>
                <Field
                  type="select"
                  id="policyLimitBIPD"
                  name="policyLimitBIPD"
                  options={limitOptions}
                  disabled={!state}
                  fast={false}
                  mode="light"
                  label="Auto Liability Limit"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  type="select"
                  id="priorCoverageInYears"
                  name="priorCoverageInYears"
                  options={yearsOptions}
                  disabled={!state}
                  fast={false}
                  mode="light"
                  label="Years of continous prior insurance"
                />
              </Grid>
              <Grid item xs={8} className={classes.checkboxContainer}>
                <Field
                  type="checkbox"
                  id="insuranceInPast31Days"
                  name="insuranceInPast31Days"
                  mode="light"
                  label="Have you had auto coverage in the last 31 days?"
                />
              </Grid>
            </>
          </Collapsible>
        </>
      )}
    </Form>
  );
};

AutoDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default flowRight(withDatePicker, withStyles(styles), connect, withStore)(AutoDetails);
