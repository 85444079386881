import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bullets: {
    margin: theme.spacing(1, 0),
    paddingLeft: theme.spacing(5)
  }
}));

export default useStyles;
