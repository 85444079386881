import React from 'react';

import { RadioGroup } from '../components/radio-group';
import { yesNoIDontKnowOptions } from '../helpers';

const PrimaryHasMedicare = ({ context, onUpdate }) => {
  return (
    <RadioGroup
      name="pipPrimaryHasQHC"
      label="Does the primary applicant have Medicare parts A & B?"
      options={yesNoIDontKnowOptions}
      value={context.data.pipPrimaryHasQHC}
      onChange={onUpdate}
    />
  );
};

export default PrimaryHasMedicare;
