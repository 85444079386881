import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  cardTop: {
    display: 'flex',
    backgroundColor: theme.colorPalette.white_10,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  card: {
    padding: theme.spacing(8)
  },
  label: {
    color: theme.colors.green_15,
    fontSize: theme.spacing(4)
  },
  divider: {
    background: theme.colors.page__background_primary,
    width: '85%',
    margin: 'auto',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4)
  },
  lowerLabel: {
    color: theme.colorPalette.green_75,
    fontSize: 12,
    marginLeft: 5
  },
  bottomMessage: {
    display: 'flex',
    alignItems: 'flex-start'
  }
});

export default makeStyles(styles);
