import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import { Tooltip } from 'core/components/tooltip';
import useStyles from './label-with-tooltip.styles';

const LabelWithTooltip = ({ label, tooltip }) => {
  const classes = useStyles();
  return (
    <>
      <Label type="body2Bold">{label}</Label>
      <Tooltip className={classes.tooltip} label="More info" text={tooltip} placement="top" />
    </>
  );
};

LabelWithTooltip.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired
};

export default LabelWithTooltip;
