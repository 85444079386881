import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './value-field.styles';
import { Label } from '../label';
import { Tooltip } from 'core/components/tooltip';

function ValueField({ classes, label, value, width, style, mode, formatter, className, showTooltip, ...props }) {
  return (
    <div className={classNames(classes.container, className)} data-cy="value-field" style={style} {...props}>
      <Label>
        {label && (
          <Label type="infoLabel" className={classes[mode ? `${mode}-label` : 'label']}>
            {label}
          </Label>
        )}
        {showTooltip && (
          <Tooltip className={classes.valueFieldTooltip} text={showTooltip.onHoverText} label="More Info" />
        )}
      </Label>
      <Label id="value" type="infoValue" className={classes[mode ? `${mode}-value` : 'value']}>
        {formatter ? formatter(value) : value}
      </Label>
    </div>
  );
}

ValueField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  mode: PropTypes.oneOf([null, 'light', 'dark', 'big-font-dark']),
  formatter: PropTypes.func,
  className: PropTypes.string,
  showTooltip: PropTypes.string
};

ValueField.defaultProps = {
  label: '',
  value: null,
  width: undefined,
  style: {},
  mode: null,
  formatter: null,
  className: '',
  showTooltip: ''
};

export default withStyles(styles)(ValueField);
