import classNames from 'classnames';
import { useObserver } from 'mobx-react';
import React from 'react';
import { Grid } from '@material-ui/core';

import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';
import { tooltipHoverTexts } from 'core/helpers/constants';
import NotificationIconWhite from 'core/assets/svg/alert-white.svg';

import useStyles from './equity-breakdown.styles';

function EquityBreakdown() {
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const classes = useStyles();
  const { policyEquityStatus: equity, currentTotalPaid, totalReceived } = store;

  // totalReceived is the amount that is coming from chargedesk,
  // currentTotalPaid is from BQ - it is "Total Cash Collected" which reflects cash in bank;
  // this field will not factor into calculations for payment schedule, total remaining, or otherwise

  const displayEquityWarning = totalReceived !== currentTotalPaid;
  return useObserver(() => (
    <Card type="secondary" className={classes.container}>
      <Grid className={classes.connectedCard}>
        <ValueField
          className={classes.valueField}
          label="Blended Daily Rate"
          formatter={currencyFormatter}
          value={equity.blendedDailyRate}
          mode="dark"
        />
        <ValueField className={classes.valueField} label="Policy Days" value={equity.policyDays} mode="dark" />
        <ValueField
          className={classNames(classes.valueField, { [classes.red]: store.paidEquityDaysRemaining < 0 })}
          label="Equity Days"
          value={store.paidEquityDaysRemaining}
          mode="dark"
          showTooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.equityDays }}
        />
        {displayEquityWarning && (
          <Grid className={classes.equityWarning}>
            <img className={classes.notificationIcon} src={NotificationIconWhite} alt="Notification Icon" />
            Equity days may not reflect recent/pending transactions
          </Grid>
        )}
      </Grid>
    </Card>
  ));
}

export default EquityBreakdown;
