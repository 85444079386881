import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  coverages: {
    rowGap: theme.spacing(4)
  },
  value: {
    color: theme.colorPalette.green_15
  },
  label: {
    fontWeight: 600
  }
}));

export default useStyles;
