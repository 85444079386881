import React from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { standaloneRentersStates } from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { useDisabledState } from 'common/disabled-context';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { SchedulePP } from 'common/components/schedule-pp';
import { getPolicyType } from 'core/helpers/policy-type';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { RentersAddressForm } from './renters-address-form';
import { RentersCoverage } from './renters-coverage';
import useStyles from './renters.styles';

function RentersOffer() {
  const classes = useStyles();
  const { disabled } = useDisabledState();
  const { offer: offerStore } = useStore();

  const selectedOption = getQuoteFriendlySelectedOption(offerStore?.selectedOption || 'HA');
  const { isRenters } = getPolicyType(
    selectedOption,
    offerStore?.offer?.quote?.noBindHome,
    offerStore?.offer?.quote?.noBindAuto
  );
  const isStandaloneRenters = isRenters && standaloneRentersStates[offerStore?.offer.quote.correctedAddress.state];

  const { values } = useFormikContext();

  const {
    rentersCoverage: { coverageCD }
  } = values;

  const personalPropertyLimit = +coverageCD.split('/')[0];

  const sppLimits = {
    tenPctOfCoverageC: personalPropertyLimit * 0.1,
    fiftyPctOfCoverageC: personalPropertyLimit * 0.5
  };

  return (
    <>
      <Section title="Renters Property Details" type="SubSection">
        <Card className={classes.container}>
          <RentersAddressForm />
        </Card>
      </Section>
      <Section title="Renters Property Coverage" type="SubSection">
        <Card>
          <RentersCoverage state={offerStore.state} />
        </Card>
      </Section>

      {isStandaloneRenters && <SchedulePP disabled={disabled} sppLimits={sppLimits} />}
    </>
  );
}

export default observer(RentersOffer);
