import { useMemo } from 'react';
import {
  policyType as PolicyType,
  lookupsJson,
  paymentType as PaymentType,
  allowInstallPayOnHomePoliciesStates,
  onlyPayInFullOnAutoPoliciesStates
} from '@ourbranch/lookups';

const usePaymentFrequencyOptions = (paymentType, policyType, state) =>
  useMemo(() => {
    let isDisabled = false;
    const paymentTypeOptions = policyType === PolicyType.Auto ? lookupsJson.autoPaymentType : lookupsJson.paymentType;
    let frequencyOptions = paymentTypeOptions;
    let tooltip = null;

    if (
      policyType === PolicyType.Home &&
      !allowInstallPayOnHomePoliciesStates[state] &&
      paymentType !== PaymentType.Monthly
    ) {
      frequencyOptions = lookupsJson.homeNewBusinessPaymentType;
      isDisabled = true;
      tooltip =
        'Home policies are not eligible to be paid monthly. Payment can be made by mortgage (escrow), or with a one-time payment in full by card or bank withdrawal (ACH).';
    }

    if (
      policyType === PolicyType.Auto &&
      onlyPayInFullOnAutoPoliciesStates[state] &&
      paymentType !== PaymentType.Monthly
    ) {
      frequencyOptions = lookupsJson.autoPaymentType.filter((opt) => opt.id === PaymentType.OneTime);
      isDisabled = true;
      tooltip = 'Auto policies are not eligible to be paid monthly in this state.';
    }

    return { frequencyOptions, isDisabled, tooltip };
  }, [paymentType, policyType, state]);

export default usePaymentFrequencyOptions;
