import React from 'react';
import PropTypes from 'prop-types';
import MUITooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import infoIcon from '../../assets/svg/info.svg';
import infoIconLight from '../../assets/svg/info-light.svg';
import useStyles from './tooltip.styles';

export function Tooltip({ text, className, placement, light, label, onClick, size, ...props }) {
  const styleClasses = useStyles({ size });
  return (
    <MUITooltip classes={{ tooltip: styleClasses.tooltip }} title={text} placement={placement} interactive arrow>
      {!label ? (
        <span className={styleClasses.iconContainer} onClick={onClick}>
          <img className={classNames(className, styleClasses.info)} src={light ? infoIconLight : infoIcon} alt={text} />
        </span>
      ) : (
        <div {...props} className={styleClasses.labelWrapper}>
          <span className={classNames(className, styleClasses.desktopLabel)}>{label}</span>
        </div>
      )}
    </MUITooltip>
  );
}

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  light: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'large'])
};

Tooltip.defaultProps = {
  className: '',
  label: '',
  onClick: () => {},
  placement: 'right-start',
  light: false,
  size: 'large'
};

export default Tooltip;
