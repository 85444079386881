import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    minWidth: 720,
    minHeight: 428,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: theme.spacing(5)
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  title: {
    color: theme.colorPalette.green_10,
    fontSize: 36,
    fontWeight: 300,
    padding: 0
  },
  text: {
    color: theme.colors.green_15,
    marginTop: 0,
    marginBottom: theme.spacing(9)
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    fontSize: 16
  },
  footer: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  button: {
    fontSize: 16,
    width: 140
  },
  'light-input-root': {
    border: '1px solid red'
  },
  separator: {
    width: '100%',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    height: 1,
    borderTop: '2px solid',
    borderColor: theme.colorPalette.beige_10
  },
  checkbox: {
    marginBottom: theme.spacing(5)
  }
});

export default makeStyles(styles);
