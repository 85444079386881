import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from 'core/components/label';
import { Radio } from 'core/components/radio';
import useStyles from './radio-group.styles';

const RadioGroup = ({ name, options, label, description, disabled, onChange, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.radioContainer}>
      <Label type="body2Bold" className={classNames(classes.radioLabel, { [classes.disabled]: disabled })}>
        {label}
      </Label>
      {description}
      <Radio
        id={name}
        name={name}
        mode="light"
        value={value}
        options={options}
        onChange={(event) => {
          onChange({ [name]: event.target.value });
        }}
        optionsSideBySide={false}
        disabled={disabled}
      />
    </div>
  );
};

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.node,
  onChange: PropTypes.func.isRequired
};

RadioGroup.defaultProps = {
  description: null
};

export default RadioGroup;
