/* eslint-disable */
import awsConfig from './aws-exports';
import Cookies from 'js-cookie';
import { debounce as _debounce } from 'lodash-es';
import * as Sentry from '@sentry/react';

const isCrawler = () => /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

const AGENCY_CHAT_ID = '3d698c17b45c1575b862ceb645b4b4bd';
const INTERNAL_HELP_DESK_CHAT_ID = '6353596ce5e684d6a05ecc03875b6356';

function initializeSegment() {
  if (isCrawler() || !awsConfig.trackingEnabled) {
    return;
  }
  !(function () {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on'
        ];
        analytics.factory = function (t) {
          return function () {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function (t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        analytics.load(awsConfig.segmentKey, {
          integrations: {
            All: true
          }
        });
        const sessionId = Cookies.get('ampSession.id') || Date.now();
        Cookies.set('ampSessionId', sessionId, { expires: 0.5 / 24 }); //session expires in 30 mins
      }
  })();
}

function initializeLogRocket() {
  if (awsConfig.trackingEnabled) {
    import('logrocket').then(({ default: LogRocket }) => LogRocket.init(awsConfig.logRocketApiKey));
  }
}

function initializeFriendBuy(merchantId) {
  window['friendbuyAPI'] = [];
  friendbuyAPI = window['friendbuyAPI'];

  friendbuyAPI.merchantId = merchantId;
  friendbuyAPI.push(['merchant', friendbuyAPI.merchantId]);
  // load the merchant SDK and your campaigns
  (function (f, r, n, d, b, u, y) {
    while ((u = n.shift())) {
      (b = f.createElement(r)), (y = f.getElementsByTagName(r)[0]);
      b.async = 1;
      b.src = u;
      y.parentNode.insertBefore(b, y);
    }
  })(document, 'script', [
    'https://static.fbot.me/friendbuy.js',
    'https://campaign.fbot.me/' + friendbuyAPI.merchantId + '/campaigns.js'
  ]);
}

export async function initializeFB() {
  if (awsConfig.friendBuyMerchantId) {
    await initializeFriendBuy(awsConfig.friendBuyMerchantId);
  }
}

/**
 * Initialize front chat and identify user in the chat.
 * If no session, still inits front chat with no session in case an external agent is having issues logging in.
 *
 * Cannot rapidly fire the identify after init or it will not work, so use setTimeout to space out the calls.
 *
 * @param {Object} session - session context object
 *
 */
export const initializeFrontChatAndIdentify = _debounce((session) => {
  try {
    console.log(`initializing front chat for session: ${JSON.stringify({ isAgency: session?.isAgency })}`);

    setTimeout(() => {
      window.FrontChat('init', {
        chatId: !session || session?.isAgency ? AGENCY_CHAT_ID : INTERNAL_HELP_DESK_CHAT_ID,
        useDefaultLauncher: false
      });
    }, 1000);

    setTimeout(() => {
      identifyUserInFront(session?.user);
    }, 2000);
  } catch (error) {
    console.log(`error initializing front chat: ${JSON.stringify(error)}`);
    Sentry.captureException(error);
  }
}, 100);

export function identifyUserInFront(user) {
  if (user) {
    console.log(`identifying user in front: ${JSON.stringify({ username: user.username, email: user.email })}`);
    window.FrontChat('identity', {
      name: user.username,
      email: user.email
    });
  }
}

export default function configureServices() {
  initializeLogRocket();
  initializeSegment();
}
