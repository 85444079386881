import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    gap: theme.spacing(4),
    width: 485
  },
  icon: {
    height: 24,
    marginRight: 12,
    width: 24
  },
  valueField: {
    marginBottom: 0,
    '& > span': {
      marginTop: 0
    },
    '& > span:last-of-type': {
      fontSize: 22,
      lineHeight: '24px'
    }
  }
}));

export default useStyles;
