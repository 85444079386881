import React from 'react';
import { Tooltip } from '@material-ui/core';

import { Card } from 'core/components/card';
import RemoveButton from 'core/components/remove-button';
import { PersonalDetails } from '../../personal-details';
import { PersonsSection } from '../../components/persons-section';
import { Row } from '../../components/row';
import NewHouseholdMemberForm from './new-household-members-form/new-household-member-form';
import useStyles from './1-household-members.styles';

const HouseholdMembers = ({ context, onUpdate }) => {
  const classes = useStyles();
  return (
    <>
      <PersonsSection title="Drivers" rightValue="driver" persons={context.data.drivers.length}>
        <Card>
          {context.data.drivers.map((driver) => (
            <Row key={driver.id}>
              <PersonalDetails person={driver}>
                <Tooltip
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  title="This household member is a driver. You cannot remove drivers here; only household members you’ve added. To add or remove a driver close and navigate to the drivers section."
                  placement="top"
                >
                  <div className={classes.buttonContainer}>
                    <RemoveButton disabled />
                  </div>
                </Tooltip>
              </PersonalDetails>
            </Row>
          ))}
        </Card>
      </PersonsSection>
      <PersonsSection title="Household Members" rightValue="household member" persons={context.data.nonDrivers.length}>
        {context.data.nonDrivers.length > 0 && (
          <Card className={classes.card}>
            {context.data.nonDrivers.map((person) => (
              <Row key={person.id}>
                <PersonalDetails person={person}>
                  <RemoveButton
                    onClick={() => {
                      onUpdate({ nonDrivers: context.data.nonDrivers.filter((resident) => resident.id !== person.id) });
                    }}
                  />
                </PersonalDetails>
              </Row>
            ))}
          </Card>
        )}
        <NewHouseholdMemberForm
          onAdd={(newResident) => {
            onUpdate({ nonDrivers: [...context.data.nonDrivers, newResident] });
          }}
        />
      </PersonsSection>
    </>
  );
};

export default HouseholdMembers;
