import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  feeBreakdownTable: {
    '& td, th': {
      borderBottom: 'none',
      padding: 15,
      whiteSpace: 'nowrap'
    },
    '& td:last-child': {
      paddingRight: 30
    },
    '& tr': {
      borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`
    },
    '& tr:last-of-type': {
      borderBottom: 'none'
    }
  },
  feeDescription: {
    color: `${theme.colorPalette.beige_10}`,
    width: '100%'
  },
  feeSummary: {
    alignItems: 'center',
    display: 'flex',
    height: 73,
    justifyContent: 'flex-end'
  },
  totalFeesLabel: {
    extend: 'columnLabel',
    marginRight: 15
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    height: 20
  },
  feeSelectorContainer: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'space-between'
  },
  card: {
    backgroundColor: theme.colorPalette.green_30,
    margin: '16px 0px',
    alignItems: 'center',
    padding: '0px 24px',
    display: 'flex'
  },
  noFeesCard: {
    alignItems: 'center',
    display: 'flex',
    height: '92px',
    justifyContent: 'center'
  },
  readOnlyFeesCard: {
    alignItems: 'center',
    display: 'flex',
    height: '64px',
    justifyContent: 'center'
  },
  title: {
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 'normal',
    color: theme.colorPalette.beige_10
  },
  subTitle: {
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 'normal',
    color: theme.colorPalette.beige_10
  },
  button: {
    color: theme.colors.button__text_primary,
    fontWeight: 600,
    backgroundColor: theme.colors.button__background_secondary,
    fontSize: 16,
    width: 196,
    marginBottom: 12
  },
  normal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  undo: {
    composes: '$normal',
    opacity: 0.6
  }
}));

export default useStyles;
