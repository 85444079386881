import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    gap: theme.spacing(2),
    margin: theme.spacing(8, 'auto', 0),
    width: 'fit-content'
  },
  button: {
    minWidth: 100,
    padding: theme.spacing(0, 4),
    fontWeight: 600,
    fontSize: 16
  }
}));

export default useStyles;
