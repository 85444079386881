/* eslint-disable func-names */
import * as Yup from 'yup';
import { addDays, isAfter, isBefore } from 'date-fns';
import { standaloneRentersStates, policyType } from '@ourbranch/lookups';

// Effective date for SA Renters policies must be after October 1st, 2022
const standaloneRentersLimitDate = new Date(2022, 9, 1);
const maxDate = new Date(new Date().setDate(new Date().getDate() + 59));

const minPolicyDateMessage = 'Policy start date must be at least 7 days in the future';

const schema = Yup.object().shape({
  global: Yup.object()
    .shape({
      homeEffectiveDate: Yup.date()
        .test('min', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Home) && !this.options.context?.noBindHome) {
            const purchaseDate = this.options.context?.home?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const nextWeek = addDays(new Date(), 6);
            let message = minPolicyDateMessage;
            let minDate = nextWeek;
            const date = new Date(obj);
            if (purchaseDateToCompare && isAfter(date, nextWeek) && isAfter(purchaseDateToCompare, nextWeek)) {
              message = 'Policy start date cannot be before the home purchase date';
              minDate = new Date(purchaseDateToCompare);
            }
            if (isBefore(date, minDate)) {
              return this.createError({ message });
            }
          }
          return true;
        })
        .test('max', 'Policy start date must be within the next 60 days.', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Home) && !this.options.context?.noBindHome) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        }),
      autoEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days.', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Auto) && !this.options.context?.noBindAuto) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('min', minPolicyDateMessage, function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Auto) && !this.options.context?.noBindAuto) {
            const nextWeek = addDays(new Date(), 6);
            const date = new Date(obj);
            return isBefore(nextWeek, date);
          }
          return true;
        }),
      rentersEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days.', function (obj) {
          if (
            this.options.context?.selectedOption?.includes(policyType.Renters) &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('standalone-renters-min', 'Policy start date must be after 10/1/2022.', function (obj) {
          if (
            this.options.context?.selectedOption?.includes(policyType.Renters) &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date >= standaloneRentersLimitDate;
          }
          return true;
        })
        .test('min', minPolicyDateMessage, function (obj) {
          if (
            this.options.context?.selectedOption?.includes(policyType.Renters) &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const nextWeek = addDays(new Date(), 6);
            const date = new Date(obj);
            return isBefore(nextWeek, date);
          }
          return true;
        }),
      condoEffectiveDate: Yup.date()
        .nullable()
        .test('min', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Condo)) {
            const purchaseDate = this.options.context?.condo?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const nextWeek = addDays(new Date(), 6);
            let message = minPolicyDateMessage;
            let minDate = nextWeek;
            const date = new Date(obj);
            if (purchaseDateToCompare && isAfter(date, nextWeek) && isAfter(purchaseDateToCompare, nextWeek)) {
              message = 'Policy start date cannot be before the condo purchase date';
              minDate = new Date(purchaseDateToCompare);
            }
            if (isBefore(date, minDate)) {
              return this.createError({ message });
            }
          }
          return true;
        })
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes(policyType.Condo)) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
    })
    .nullable()
});

export default schema;
