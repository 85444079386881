import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { observer } from 'mobx-react';
import React from 'react';
import useSession from 'core/hooks/use-session';

import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';

import useStyles from './styles';
import FeeRow from './fee-row';
import FeeSelector from './fee-selector';
import { Card } from 'core/components/card';
import { Grid } from '@material-ui/core';

function FeeBreakdown() {
  const { isExternalSales, viewOnly, isService, isInternalAgent } = useSession();
  const classes = useStyles();
  const {
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const readOnly = viewOnly || isExternalSales;
  return (
    <>
      <div className={classes.header}>
        <Label type="coverageValue">Fee Breakdown</Label>
        <div className={classes.feeSummary}>
          <Label className={classes.totalFeesLabel} type="infoLabel">
            Total
          </Label>
          <ValueField
            className={classes.valueField}
            formatter={currencyFormatter}
            value={store.totalFeeCost}
            mode="dark"
          />
        </div>
      </div>
      <>
        <Card container className={classes.card}>
          {store.fees.length > 0 ? (
            <>
              <TableContainer>
                <Table className={classes.feeBreakdownTable}>
                  <TableBody>
                    {store.fees.map((fee, type) => (
                      <FeeRow key={type} fee={fee} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              className={readOnly ? classes.readOnlyFeesCard : classes.noFeesCard}
            >
              <Label className={classes.title}>There are no fees associated with this policy</Label>
              {!readOnly && <Label className={classes.subTitle}>To add a fee select from below</Label>}
            </Grid>
          )}
        </Card>
      </>
      {!readOnly && (
        <FeeSelector
          geographicState={store.geographicState}
          policyType={store.policyType}
          disabled={!isService || !isInternalAgent}
        />
      )}
    </>
  );
}

export default observer(FeeBreakdown);
