import React from 'react';
import PropTypes from 'prop-types';

import Section from 'core/components/section';
import useStyles from './persons-section.styles';

const PersonsSection = ({ title, rightValue, persons, children }) => {
  const classes = useStyles();
  return (
    <Section
      title={title}
      type="InnerSection"
      className={classes.persons}
      rightLabel={rightValue ? 'TOTAL' : null}
      rightValue={rightValue ? `${persons} ${rightValue}${persons !== 1 ? 's' : ''}` : null}
    >
      {children}
    </Section>
  );
};

PersonsSection.propTypes = {
  title: PropTypes.string,
  rightValue: PropTypes.string,
  persons: PropTypes.number,
  children: PropTypes.node
};

PersonsSection.defaultProps = {
  title: null,
  rightValue: null,
  children: null,
  persons: null
};

export default PersonsSection;
