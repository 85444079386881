import { types, flow, getParent } from 'mobx-state-tree';
import { runSearch } from './search.service';

export const SearchStore = types
  .model({
    customersResults: types.optional(types.frozen(), {}),
    offersResults: types.optional(types.frozen(), {}),
    policiesResults: types.optional(types.frozen(), {}),
    loading: types.boolean,
    searchString: types.optional(types.string, ''),
    currentResultsPage: types.optional(types.integer, 0),
    showCustomerMatchesModal: types.boolean,
    showReferralMatchesModal: types.optional(types.boolean, false),
    resultsPerPage: types.optional(types.integer, 10),
    expandedSearch: types.optional(types.boolean, false),
    showOfferHistory: types.optional(types.boolean, false),
    showOfferMatchesModal: types.boolean,
    error: types.maybeNull(types.frozen())
  })
  .views((self) => ({
    get algoliaKey() {
      return getParent(self).userPreferences.algoliaKey;
    }
  }))
  .actions((self) => ({
    setSearchString(value) {
      self.searchString = value;
    },
    searchOffersAction: flow(function* searchOffers() {
      self.loading = true;
      try {
        self.offersResults = yield runSearch(
          {
            searchString: self.searchString,
            index: 'offers',
            page: self.currentResultsPage,
            hitsPerPage: self.resultsPerPage,
            expandedSearch: self.expandedSearch,
            showOfferHistory: self.showOfferHistory
          },
          self.algoliaKey
        );
        self.loading = false;
      } catch (error) {
        self.error = error;
      } finally {
        self.loading = false;
      }
    }),
    searchCustomersAction: flow(function* searchCustomers() {
      self.loading = true;

      // this logic allows people to search for policy IDs in the Member index
      const policyIdRegex = new RegExp(/^[0-9]{9}-[a-z]{4}[0-9]*-[0-9]{3}-[A-Z]{2}$/);
      const searchStringElements = self.searchString.split(' ');
      const updatedSearchString = searchStringElements
        .map((element) => {
          if (element.match(policyIdRegex)) {
            return element.split('-')[0]; // replace any policy Ids in the search string with just the account ID because this is the Member index
          }
          return element;
        })
        .join(' ');

      try {
        self.customersResults = yield runSearch(
          {
            searchString: updatedSearchString,
            index: 'users',
            page: self.currentResultsPage,
            hitsPerPage: self.resultsPerPage,
            expandedSearch: self.expandedSearch
          },
          self.algoliaKey
        );
      } catch (error) {
        self.error = error;
      } finally {
        self.loading = false;
      }
    }),
    searchPoliciesAction: flow(function* searchPolicies() {
      self.loading = true;
      try {
        self.policiesResults = yield runSearch(
          {
            searchString: self.searchString,
            index: 'policy',
            page: self.currentResultsPage,
            hitsPerPage: self.resultsPerPage
          },
          self.algoliaKey
        );
      } catch (error) {
        self.error = error;
      } finally {
        self.loading = false;
      }
    }),
    searchStaffUsers: flow(function* searchStaffUsers({ username }) {
      const response = yield runSearch(
        {
          searchString: username,
          index: 'staffUsers',
          page: 0,
          hitsPerPage: 10
        },
        self.algoliaKey
      );
      // algolia will return the best match as first result
      const user = response.hits[0];
      if (user?.Username === username) {
        return user;
      }
      return undefined;
    }),
    clearInput() {
      self.searchString = '';
    },
    resetCustomerResults() {
      self.customersResults = {};
    },
    setCurrentResultsPage(page) {
      self.currentResultsPage = page;
    },
    setShowCustomerMatches(show = true) {
      self.showCustomerMatchesModal = show;
    },
    setShowReferralMatches(show = true) {
      self.showReferralMatchesModal = show;
    },
    setExpandedSearch(expanded) {
      self.expandedSearch = expanded;
    },
    setShowOfferHistory(show) {
      self.showOfferHistory = show;
    }
  }));
