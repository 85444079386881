import * as Yup from 'yup';

import { isBefore, isAfter } from 'date-fns';

// this is why we need to replace the dashes with slashes, but preferably, don't use new Date() on the frontend
// https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off?page=1&tab=scoredesc#tab-top

export const validationSchema = Yup.object()
  .shape({
    billingHoldUntil: Yup.string()
      .test(
        'incorrect',
        'Billing hold cannot be on this date. See calendar picker for available dates.',
        function test(value) {
          if (this.options.context.isTeamLeader) {
            return true;
          }
          if (
            isAfter(
              new Date(value.replace(/-/g, '/')),
              new Date(this.options.context.maxBillingHoldDate.replace(/-/g, '/'))
            )
          ) {
            return this.createError({
              message: `Billing hold cannot be after ${this.options.context.maxBillingHoldDate}. See calendar picker for available dates.`
            });
          }
          return true;
        }
      )
      .test('invalid', 'Billing holds cannot be placed within 7 days of the policy end date', function test(value) {
        return isBefore(new Date(value), this.options.context.sevenDaysBeforeEndDate);
      })
      .nullable()
  })
  .nullable()
  .default(null);
