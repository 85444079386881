import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';

import AgencySupportBanner from 'core/components/agency-support-banner';
import { useStore } from 'core/store';
import { track, identify } from 'core/helpers/analytics';
import { Select } from 'core/components/select';
import { NotificationCard } from 'core/components/notification-card';
import Checkbox from 'core/components/checkbox';
import closeIcon from 'core/assets/svg/x.svg';
import { Label } from 'core/components/label';
import { ActionButton } from 'core/components/action-button';
import { useToast } from 'core/components/toast';
import useSession from 'core/hooks/use-session';
import { lookupsJson, deletedDriversReasons } from '@ourbranch/lookups';
import useStyles from './remove-driver-modal.styles';

const RemoveDriverModal = ({ open, onClose, onRemove, driver }) => {
  const classes = useStyles();

  const { user, isAgency } = useSession();
  const toast = useToast();
  const [errors, setErrors] = useState({ reason: false, accepted: false });
  const [reason, setReason] = useState('');
  const [accepted, setAccepted] = useState(false);
  const {
    account: {
      policies: {
        policy: { policy }
      }
    },
    offer
  } = useStore();
  const { values, setFieldValue } = useFormikContext();

  const handleSubmit = () => {
    if (!accepted || !reason) {
      if (!reason) {
        setReason('');
      }
      setErrors({ reason: !reason, accepted: !accepted });
      return;
    }

    const webUserId = offer?.offer?.webUserId || policy?.webUserId;
    const driverName = `${driver?.firstName} ${driver?.lastName}`;

    identify(user.username);

    track('Removed Driver', {
      user: user.username,
      driverName,
      removedReason: reason,
      offerId: offer?.offer?.id || 'N/A',
      policyId: policy?.id || 'N/A',
      webUserId
    });

    toast.notify({
      type: 'success',
      message: `${driverName} was successfully removed.`
    });

    const newRemovedDrivers = values?.removedDrivers
      ? [...values?.removedDrivers, { ...driver, removedReason: reason }]
      : [{ ...driver, removedReason: reason }];

    setFieldValue('removedDrivers', newRemovedDrivers);
    onRemove();
    onClose();
  };

  const showLimitedCoverageNotification = reason && reason !== deletedDriversReasons.RESIDENT_RELATIVE_OWN_INS;
  const showCoverageWillNeedVerificationNotification =
    reason && reason === deletedDriversReasons.RESIDENT_RELATIVE_OWN_INS;

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle>
        <Label className={classes.title}>Remove Driver</Label>
      </DialogTitle>
      <DialogContent>
        <p className={classes.text}>You can remove a driver if they are not a regular driving risk in the household.</p>
        <Grid container>
          <Grid item xs={4} className={classes.data}>
            <Label type="infoLabel">Driver</Label>
            <Grid item>
              {driver?.firstName} {driver?.lastName}
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.data}>
            <Label type="infoLabel">Age</Label>
            <Grid item>{driver?.age}</Grid>
          </Grid>
        </Grid>
        <Grid container justify="space-between">
          <Grid item xs={12}>
            <Select
              id="reason"
              name="reason"
              label="Valid reason for removal"
              aria-label="Enter a valid reason for removal"
              onChange={(name, value) => {
                setReason(value);
                setErrors({ accepted: false, reason: false });
              }}
              value={reason}
              error={errors?.reason}
              helperText={errors?.reason ? 'Please select a valid reason for removal' : undefined}
              options={lookupsJson?.removedDriverReasonOptions}
              mode="light"
              fullWidth
              className="no-margin-bottom"
              classes={errors?.reason ? { 'light-inputRoot': classes['light-input-root'] } : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            {showLimitedCoverageNotification && (
              <NotificationCard type="light" header="Please keep in mind">
                A removed driver's coverage may be limited or denied in the result of a claim. If this driver has
                regular access to the member’s vehicle as part of their household and does not have coverage elsewhere,
                the driver should not be removed.
              </NotificationCard>
            )}
            {showCoverageWillNeedVerificationNotification && (
              <NotificationCard type="light" header="Proof of other coverage will be required">
                To ensure all resident drivers are equally protected, we ask that resident relatives not listed as
                drivers have auto liability limits equal to or greater than the limits on this policy. Branch will
                require proof of the removed driver's coverage (policy declarations or auto ID card) to confirm adequate
                limits for this driver.
              </NotificationCard>
            )}
            {(showLimitedCoverageNotification || showCoverageWillNeedVerificationNotification) && (
              <Checkbox
                id="attestation"
                mode="light"
                name="attestation"
                label="I attest that I've communicated the above considerations to the member."
                value={accepted}
                onChange={(e) => {
                  setAccepted(e.target.checked);
                  setErrors({ ...errors, accepted: false });
                }}
                error={errors.accepted}
                className={classes.checkbox}
              />
            )}
          </Grid>
        </Grid>

        <div className={classes.footer}>
          <Button type="button" variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
            Remove Driver
          </Button>
          {isAgency && (
            <>
              <div className={classes.separator} />
              <AgencySupportBanner content="Don’t see the reason you're looking for? The driver may not be eligible to be removed. Contact us for help." />
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default observer(RemoveDriverModal);
