import camelCase from 'lodash-es/camelCase';

/**
 * ElemType - works as an enum for possible values used as the type argument calling
 * MapClassesToElem, however this function could be called with any string
 */
export const ElemType = {
  Label: 'label',
  Input: 'input',
  FormHelper: 'formHelper',
  Button: 'button',
  Select: 'select',
  Paper: 'paper',
  LinearProgress: 'linearProgress',
  CircularProgress: 'circularProgress',
  IconButton: 'iconButton',
  TextField: 'textField',
  TableCell: 'tableCell',
  TableRow: 'tableRow',
  TableSortLabel: 'tableSortLabel',
  InputAdornment: 'inputAdornment',
  Checkbox: 'checkbox',
  Modal: 'modal',
  Radio: 'radio',
  FormControlLabel: 'controlLabel'
};

/**
 * MapClassesToElem - extract a set of style classes to overwrite MUI components styles
 * if you need to overwrite 'root' and 'label' classes for 'input'
 * you can call the function like this:
 *
 * elemType: ElemType.Input
 * classes: { inputRoot: { ...rootStyles }, inputLabel: { ...labelStyles } }
 * output: { root: { ...rootStyles }, label: { ...labelStyles } }
 *
 * @param type one of the elements from the ElemType enum
 * @param classes JSS Classes object with namespaces
 * @returns JSS classes object for one specific namespace
 */
export const MapClassesToElem = (type, classes) =>
  Object.entries(classes)
    .filter(([key]) => key.startsWith(type))
    .reduce(
      (ret, [key, val]) => ({
        ...ret,
        [camelCase(key.substr(type.length))]: val
      }),
      {}
    );

/**
 * Given a hex returns the color on rgb format with the indicated alpha
 * ie: #757774, 0.5 => rgb(117,119,116, 0.5)
 * @param {string} hex
 * @param {number} opacity
 */
export const hexToRGB = (h, opacity = null) => {
  let r = 0;
  let g = 0;
  let b = 0;

  if (h.length === 4) {
    // 3 digits
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    // 6 digits
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }

  const rgb = `${+r},${+g},${+b}`;
  return !opacity ? `rgb(${rgb})` : `rgba(${rgb}, ${opacity})`;
};
