import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    background: theme.colorPalette.white_30
  },
  btn: {
    width: '100%',
    marginTop: 20
  },
  inspectionInfoContainer: {
    padding: '5px 5px 15px 5px'
  },
  status: {
    borderRadius: 18,
    width: 'fit-content',
    height: 33,
    alignContent: 'space-around',
    marginTop: 5,
    marginBottom: 10
  },
  notOrdered: {
    padding: 15,
    border: `solid 1px ${theme.colorPalette.green_10}`
  },
  inProgress: {
    padding: '1px 16px 1px 0px',
    border: `solid 1px ${theme.colorPalette.green_10}`
  },
  completed: {
    padding: '1px 16px 1px 0px',
    border: `solid 1px #87c540`
  },
  failed: {
    padding: '1px 16px 1px 0px',
    border: `solid 1px ${theme.colorPalette.red_20}`
  },
  completedIcon: {
    marginRight: 5,
    color: '#87c540',
    width: 30,
    height: 30
  },
  inProgressIcon: {
    marginRight: 5,
    color: theme.colorPalette.green_10,
    width: 30,
    height: 30
  },
  failedIcon: {
    marginRight: 5,
    color: theme.colorPalette.red_20,
    width: 30,
    height: 30
  }
}));

export default useStyles;
