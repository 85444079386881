import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import { observer } from 'mobx-react';
import React from 'react';

import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { currencyFormatter } from 'core/helpers/formatters';
import { Tooltip } from 'core/components/tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';

import useStyles from './price-breakdown.styles';

function PriceBreakdown({ premium, fees, surplus }) {
  const classes = useStyles();
  return (
    <>
      <Label type="coverageValue">Price Breakdown</Label>
      {process.env.NODE_ENV !== 'production' && (
        <p className={classes.inaccurateValueNote}>
          INFO: &quot;Price Breakdown&quot; values are accurate only in a production enviroment.
        </p>
      )}
      <Card className={classes.container} alignItems="center" justify="space-between" container>
        <TableContainer>
          <Table className={classes.priceBreakdownTable}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.label}>
                  <Label className={classes.label}>Total Premium</Label>
                  <Label className={classes.value}>{currencyFormatter(premium)}</Label>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Label className={classes.label}>
                    Total Surplus
                    <Tooltip className={classes.tooltip} text={tooltipHoverTexts.priceDetailsSurplus} />
                  </Label>
                  <Label className={classes.value}>{currencyFormatter(surplus)}</Label>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Label className={classes.label}>
                    Total Fees
                    <Tooltip className={classes.tooltip} text={tooltipHoverTexts.priceDetailsFees} />
                  </Label>
                  <Label className={classes.value}>{currencyFormatter(fees)}</Label>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

export default observer(PriceBreakdown);
