import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  cardContainer: {
    width: 720,
    margin: theme.spacing(0, 'auto'),
    padding: theme.spacing(8),
    backgroundColor: theme.colors.page__background_primary,
    border: `2px solid ${theme.colorPalette.white_10}`
  },
  content: {
    fontSize: 14,
    lineHeight: 1.43,
    margin: '10px 0px!important'
  },
  link: {
    color: theme.palette.secondary.main
  },
  checklistContainer: {
    maxWidth: 'none'
  }
});

export default makeStyles(styles);
