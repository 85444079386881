import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  subSection: {
    borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`,
    padding: '24px 32px',
    '&:last-of-type': {
      borderBottom: 'none'
    }
  },
  loadingWheel: {
    boxShadow: 'none'
  },
  container: {
    position: 'relative',
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    backgroundColor: theme.colors.card_background_secondary
  },
  cardHeader: {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 100,
    maxHeight: 100,
    backgroundColor: theme.colorPalette.white_10_op_10,
    padding: '32px 30px'
  },
  iconFold: {
    display: 'block',
    height: 32,
    width: 32,
    transition: 'transform 200ms'
  },
  icon: {
    composes: '$iconFold',
    transform: 'rotate(180deg)'
  },
  connectedCard: {
    display: 'flex',
    flexGrow: 0,
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(16),
    padding: 32
  }
}));

export default useStyles;
