import React from 'react';

import { Label } from 'core/components/label';
import { RadioGroup } from '../../components/radio-group';
import { yesNoIDontKnowOptions } from '../../helpers';
import useStyles from './3a-residents-with-qhc.styles';

const ResidentsWithQHC = ({ context, onUpdate }) => {
  const classes = useStyles();
  return (
    <RadioGroup
      name="pipResidentsHaveQHC"
      label="Do any drivers or household members have these health coverages?"
      options={yesNoIDontKnowOptions}
      value={context.data.pipResidentsHaveQHC}
      onChange={onUpdate}
      description={
        <ul className={classes.bullets}>
          <li>
            <Label type="body2">Medicare with parts A & B</Label>
          </li>
          <li>
            <Label type="body2">Medicaid</Label>
          </li>
          <li>
            <Label type="body2">
              Personal health insurance covering medical expenses for a motor vehicle accident with an annual individual
              deducible of $6,000 or less
            </Label>
          </li>
        </ul>
      }
    />
  );
};

export default ResidentsWithQHC;
