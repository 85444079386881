const styles = (theme) => ({
  container: {
    marginBottom: 8
  },
  label: {
    textTransform: 'uppercase'
  },
  value: {
    minHeight: 20,
    marginTop: 5
  },
  'dark-label': {
    composes: '$label',
    color: theme.colors.light_text,
    opacity: 0.6
  },
  'dark-value': {
    composes: '$value',
    color: theme.colors.light_text
  },
  valueFieldTooltip: {
    position: 'relative',
    top: -3,
    margin: theme.spacing(0, 1)
  }
});

export default styles;
