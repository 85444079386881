import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  radioLabel: {
    color: theme.colors.green_15
  },
  disabled: {
    opacity: 0.5
  }
}));

export default useStyles;
