import React from 'react';

import { RadioGroup } from '../components/radio-group';
import { excludeEveryoneOptions } from '../helpers';

const ExcludeEveryone = ({ context, onUpdate }) => {
  return (
    <RadioGroup
      name="excludeEveryone"
      label="Exclude all drivers and household members from PIP ME coverage?"
      options={excludeEveryoneOptions}
      value={context.data.excludeEveryone}
      onChange={onUpdate}
    />
  );
};

export default ExcludeEveryone;
