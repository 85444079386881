import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import styles from './remove-button.styles';
import remove from '../../assets/svg/remove.svg';

function RemoveButton({ classes, onClick, mode, disabled }) {
  return (
    <IconButton
      disabled={disabled}
      classes={
        mode
          ? MapClassesToElem(`${mode}-${ElemType.IconButton}`, classes)
          : MapClassesToElem(ElemType.IconButton, classes)
      }
      onClick={onClick}
    >
      <img src={remove} alt="remove" />
    </IconButton>
  );
}

RemoveButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  mode: PropTypes.oneOf([null, 'big']),
  disabled: PropTypes.bool
};

RemoveButton.defaultProps = {
  mode: null,
  disabled: false,
  onClick: () => {}
};

export default withStyles(styles)(RemoveButton);
