import gql from 'graphql-tag';
import * as fragments from './policy.query.fragments';

export const CONFIRM = gql`
  mutation confirmPolicyChange($policyId: ID!, $previewId: ID!, $internalNotes: String!) {
    confirmPolicyChange(policyId: $policyId, previewId: $previewId, internalNotes: $internalNotes) {
      success
      reason
    }
  }
`;

export const CHANGE_POLICY = gql`
  mutation previewPolicyChangePolicy($policy: PolicyInput!) {
    previewPolicyChangePolicy(policy: $policy) {
      ...PolicyPreviewResponse
    }
  }
  ${fragments.PolicyPreviewResponse}
`;

export const ADD_SEGMENT = gql`
  mutation previewPolicyChangeAddSegment($policyId: ID!, $segment: PolicyDetailsInput!) {
    previewPolicyChangeAddSegment(policyId: $policyId, segment: $segment) {
      ...PolicyPreviewResponse
    }
  }
  ${fragments.PolicyPreviewResponse}
`;
export const CHANGE_SEGMENT = gql`
  mutation previewPolicyChangeToSegment($policyId: ID!, $segmentId: ID!, $segment: PolicyDetailsInput!) {
    previewPolicyChangeToSegment(policyId: $policyId, segmentId: $segmentId, segment: $segment) {
      ...PolicyPreviewResponse
    }
  }
  ${fragments.PolicyPreviewResponse}
`;

export const ADD_HOLD_CARD = gql`
  mutation addHoldCard($policyId: ID!, $reason: String!) {
    addHoldCard(policyId: $policyId, reason: $reason) {
      policyId
      createdBy
      createdDate
      reason
      deleted
      success
      holdCardId
    }
  }
`;
export const PREVIEW_POLICY_CHANGE_SCRUB_INCIDENTS = gql`
  mutation previewPolicyChangeScrubIncidents($policy: ScrubIncidentsInput!, $incidentDates: [AWSDate]!) {
    previewPolicyChangeScrubIncidents(policy: $policy, incidentDates: $incidentDates) {
      id
      coverageChange
      itemsChanged
      billingDescription
      newPremium
      newTotalPrice
    }
  }
`;
export const GET_HOLD_CARDS = gql`
  query ($policyId: ID!) {
    holdcards: getHoldCards(policyId: $policyId) {
      holdCardId
      createdBy
      createdDate
      reason
      deleted
      policyId
    }
  }
`;

export const REMOVE_HOLD_CARD = gql`
  mutation removeHoldCard($policyId: ID!, $holdCardId: ID!) {
    removeHoldCard(policyId: $policyId, holdCardId: $holdCardId) {
      holdCardId
      deleted
      policyId
    }
  }
`;

export const RECREATE_APPLICATION = gql`
  mutation recreatePolicyApplication($policyId: ID!) {
    pdfUrl: recreatePolicyApplication(policyId: $policyId)
  }
`;

export const GET_POLICY_EQUITY_STATUS = gql`
  query ($policyId: ID!) {
    getPolicyEquityStatus(policyId: $policyId) {
      blendedPremium
      policyDays
      totalPremiumBilled
      totalPremiumReceived
    }
  }
`;

export const GET_POLICY_PAID_AND_PENDING = gql`
  query ($policyId: ID!) {
    PolicyPaidAndPendingCash: getPolicyPaidAndPendingCash(policyId: $policyId) {
      currentTotalPaid
      pendingAmount
    }
  }
`;

export const GET_RENEWAL_POLICY_ID = gql`
  query ($policyId: ID!) {
    renewalPolicyId: getRenewalPolicyId(policyId: $policyId)
  }
`;

export const ORDER_INSPECTION = gql`
  mutation orderInspection(
    $policyId: ID!
    $address: AddressDetailsInput!
    $mailingAddress: AddressDetailsInput!
    $fname: String!
    $lname: String!
    $phone: String!
    $coverageA: Float!
    $effectiveDate: String!
    $wildfireHazardScore: String
    $homeAge: Int
  ) {
    inspection: orderInspection(
      policyId: $policyId
      address: $address
      mailingAddress: $mailingAddress
      fname: $fname
      lname: $lname
      phone: $phone
      coverageA: $coverageA
      effectiveDate: $effectiveDate
      wildfireHazardScore: $wildfireHazardScore
      homeAge: $homeAge
    ) {
      ... on OrderInspectionSuccess {
        dateOrdered
        status
        policyId
        caseNumber
        inspectionId
      }
      ... on OrderInspectionError {
        error
      }
    }
  }
`;

export const GET_INSPECTION_STATUS = gql`
  query ($policyId: ID!) {
    inspection: getInspectionStatus(policyId: $policyId) {
      status
      caseNumber
      inspectionId
      dateOrdered
      dateCompleted
      completedType
    }
  }
`;

export const GET_CD_DIFF = gql`
  query ($policyId: String!, $policyPreviewId: String!) {
    requests: getPolicyPreviewCdRequestData(policyId: $policyId, policyPreviewId: $policyPreviewId) {
      diff
      requestIdAfterPreview
      requestIdBeforePreview
      xmlBeforePreview {
        success
        message
        data
      }
      xmlAfterPreview {
        success
        message
        data
      }
    }
  }
`;

export const GET_BIX_CONVERSION_SIGNATURE_FROM_TABLE = gql`
  query getBixConversationSignatureFromTable($policyId: ID!) {
    getBixConversationSignatureFromTable(policyId: $policyId) {
      policyId
      signedTimestampISO8601
    }
  }
`;

export const GET_PALOMAR_POLICY_DOC = gql`
  query getPalomarPolicyDoc($palomarPolicyId: String!, $repEmail: String!, $policyType: String!) {
    palomarPolicyDoc: getPalomarPolicyDoc(
      palomarPolicyId: $palomarPolicyId
      repEmail: $repEmail
      policyType: $policyType
    ) {
      palomarPolicyDecPage
    }
  }
`;

export const REWRITE_POLICY = gql`
  mutation rewritePolicy($policyId: ID!, $newEffectiveDate: AWSDate!, $internalNotes: String) {
    rewritePolicy(policyId: $policyId, newEffectiveDate: $newEffectiveDate, internalNotes: $internalNotes) {
      success
      message
    }
  }
`;
