import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { fromJS } from 'immutable';
import flowRight from 'lodash-es/flowRight';
import { paymentMethod as PaymentMethods } from '@ourbranch/lookups';

import config from 'aws-exports';
import { withStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { withToast } from 'core/components/toast/context';
import { track } from 'core/helpers/analytics';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import { ValidationErrorsModal, useValidationErrorsModal } from 'core/components/validation-errors-modal';
import { getStartMinMaxDate, localToUtcTime } from '../dates';
import Footer from '../footer';
import CancelledPolicyFooter from '../footer/cancelled-policy-footer';
import CancellationForm from '../footer/cancellation-form';
import NonRenewForm from '../footer/non-renew-form';
import ReinstateForm from '../footer/reinstate-form';
import { AddHoldCard } from '../hold-card';
import RewriteModal from '../rewrite-modal';
import { validationSchema } from './policy-settings.validation';
import PolicySettings from './policy-settings';
import NoReinstatementModal from './no-reinstatement-modal';
import useStyles from './policy-settings.styles';

const stripePromise = loadStripe(config.stripeKey);

const endDateValue = (values) => {
  if (values.reinstateDate) {
    return values.reinstateDate;
  }
  return values.endDate;
};

const parseValues = (values, isReinstatementOrRescind) => ({
  ...values,
  autoRenew: values.renew,
  cancelReason: values.nonRenewReason || values.cancelReason,
  endDate: endDateValue(values),
  // for reinstatement, don't send in fees because the backend will determine what fees to generate on a reinstatement
  fees: isReinstatementOrRescind ? undefined : values.fees
});

const getPrimaryMortgage = ({ policy, primaryMortgageForBilling }) => {
  const emptyMortgage = {
    loanNumber: undefined,
    mortgageHolderAddress: undefined,
    mortgageHolderName: undefined,
    mortgageHolderId: undefined
  };

  if (policy?.defaultEscrowAccount?.mortgageHolderName) {
    return policy.defaultEscrowAccount;
  }
  return primaryMortgageForBilling || emptyMortgage;
};

const PolicySettingsContainer = ({
  loadingPreview,
  onPreviewPolicy,
  accountId,
  store: {
    account: {
      policies: {
        policy: { segment, setChanged, mustDelayPayments, policy, billingDetails, addHoldCard, policyDetails }
      }
    }
  },
  goToPaymentTab,
  toast
}) => {
  const [state, setState] = useState(
    fromJS({
      cancelPolicy: false,
      isNonRenew: false,
      effectiveDateTouched: false,
      modal: ''
    })
  );

  const classes = useStyles();
  const { showValidationErrorsModal, setShowValidationErrorsModal } = useValidationErrorsModal();
  const {
    canBackDate,
    canReinstate,
    isAgency,
    canRescindCancellation,
    canEdit,
    viewOnly,
    canRewritePolicy,
    canReinstateCNPY
  } = useContext(AuthContext);

  const policyStatus = getPolicyStatus(policy);
  const { bt } = useBasisTheory(config.basisTheoryApiKey, { elements: true });

  const [showNoReinstatementModal, setShowNoReinstatementModal] = useState(false);

  const [showRewriteModal, setShowRewriteModal] = useState(false);
  const property = segment.home || segment.condo;
  const primaryMortgageForBilling = property?.mortgageDetails?.find((mortgage) => mortgage?.primary);
  const primaryMortgage = getPrimaryMortgage({ policy, primaryMortgageForBilling });
  const { defaultBankAccount, defaultCreditCard } = useMemo(() => {
    // not all accounts will have a default so set it from activePaymentMethod
    let defaultBankAccount = policy.defaultBankAccount;
    let defaultCreditCard = policy.defaultCreditCard;
    const activeAccount = billingDetails?.activePaymentMethod;

    const activeBankAccount = activeAccount?.id?.startsWith('ba')
      ? billingDetails.activePaymentMethod
      : billingDetails?.allPaymentMethods?.find((account) => account?.id?.startsWith('ba'));
    const activeCreditCard = activeAccount?.id?.startsWith('card')
      ? billingDetails.activePaymentMethod
      : billingDetails?.allPaymentMethods?.find((account) => account?.id?.startsWith('card'));

    // in some cases, the active account in stripe and the default card get out of sync, use this opportunity to match them up
    if (activeBankAccount?.id !== policy.defaultBankAccount?.id) {
      defaultBankAccount = activeBankAccount || { id: undefined };
    }
    if (activeCreditCard?.id !== policy.defaultCreditCard?.id) {
      defaultCreditCard = activeCreditCard || { id: undefined };
    }
    return { defaultBankAccount, defaultCreditCard };
  }, [policy, billingDetails]);

  const { minDate, maxDate } = getStartMinMaxDate({
    policy,
    editCurrentSegment: true,
    currentSegment: policy.segments[0],
    canBackDate,
    editingPolicySettings: true
  });

  const cancelled = policyStatus === PolicyStatus.InCancellation || policyStatus === PolicyStatus.Cancelled;
  const allowReinstatement = canReinstate && policyStatus === PolicyStatus.Cancelled;
  const allowRescind = canRescindCancellation && policyStatus === PolicyStatus.InCancellation;

  const cancelledFooterActionText =
    (allowReinstatement && 'Reinstate Policy') || (allowRescind && 'Rescind Cancellation');
  const isReinstatementOrRescind = allowReinstatement || allowRescind;
  const handleCancellation = useCallback(
    (cancelPolicy, changed = true) => {
      setState(state.setIn(['cancelPolicy'], cancelPolicy).setIn(['changed'], changed));
    },
    [setState, state]
  );

  const handleAddHoldCard = useCallback(() => {
    setState(state.setIn(['modal'], 'addHoldCard'));
  }, [setState, state]);

  const closeModal = useCallback(() => {
    setState(state.setIn(['modal'], ''));
  }, [setState, state]);

  const handleChangePolicy = useCallback(
    (values) => {
      const parsed = parseValues(values, isReinstatementOrRescind);
      onPreviewPolicy(parsed);
    },
    [onPreviewPolicy]
  );

  const onHoldCardAddition = useCallback(
    (args) => {
      const { reason, policyId } = args;
      addHoldCard(reason, policyId)
        .then((res) => {
          track('Staff Add Hold Card', { args, res });
          toast.notify({
            type: 'success',
            message: 'The hold card was added successfully'
          });
        })
        .catch((res) => {
          Sentry.captureException(
            JSON.stringify({
              res,
              body: {
                reason,
                policyId
              }
            })
          );
        });
    },
    [addHoldCard, toast]
  );

  return (
    <Formik
      initialValues={{
        ...policy,
        defaultEscrowAccount: primaryMortgage,
        renewalCreditPull: !!policy.renewalCreditPull,
        blockCreditCheckAtRenewal: policy?.blockCreditCheckAtRenewal || false,
        defaultBankAccount,
        defaultCreditCard,
        cancellationEndDate: policy.endDate,
        nonRenewReason: undefined,
        isNonRenew: false,
        cancelReason: policy.cancelReason === 'NA' ? undefined : policy.cancelReason, // clear out the NA value because policies are bound with this
        skipImmediateBillOrRefund: policy.skipImmediateBillOrRefund || mustDelayPayments,
        salesRep: policy.salesRep || policy?.offer?.quote?.rep
      }}
      validationSchema={() =>
        Yup.lazy((values) =>
          validationSchema({
            policyType: policy.policyType,
            values,
            minDate,
            maxDate,
            currentEffectiveDate: policy.effectiveDate,
            wasEffectiveDateUpdated: policy.effectiveDate !== values.effectiveDate && state.get('effectiveDateTouched'),
            policyStatus,
            policyDetails,
            billingDetails,
            term: policy.term
          })
        )
      }
      onSubmit={handleChangePolicy}
    >
      {({ handleSubmit, touched, values, validateForm, setErrors, setTouched }) => {
        const { cancel, endDate, fullTermPolicyEndDate, state: policyState } = values;
        const changed = canEdit && Object.keys(touched).length > 0;

        const policyEndDateIsBeforeOriginalEndDate =
          localToUtcTime(`${endDate}T23:59:59.99`, policyState) <
          localToUtcTime(`${fullTermPolicyEndDate}T23:59:59.99`, policyState);

        const previewPolicyCancellation = cancelled || cancel || policyEndDateIsBeforeOriginalEndDate;

        const onSubmit = async () => {
          setState(state.setIn(['effectiveDateTouched'], !!touched.effectiveDate));

          // if the policy is CANCELLED and it was cancelled due to non-payment then we need to show the no reinstatement modal
          // exceptions: if user has permission group or the policy is Escrow payment method
          if (
            policyStatus === PolicyStatus.Cancelled &&
            policy?.cancelReason &&
            policy.cancelReason === 'CNPY' &&
            !canReinstateCNPY &&
            values.paymentMethod !== PaymentMethods.Escrow
          ) {
            setShowNoReinstatementModal(true);
            return;
          }

          const errors = await validateForm();

          if (Object.keys(errors).length) {
            setErrors(errors);
            setTouched(errors);
            setShowValidationErrorsModal(true);
          } else {
            handleSubmit();
          }
        };

        setChanged(changed);

        return (
          <BasisTheoryProvider bt={bt}>
            {showNoReinstatementModal && (
              <NoReinstatementModal
                open={showNoReinstatementModal}
                onClose={() => setShowNoReinstatementModal(false)}
              />
            )}
            <Elements stripe={stripePromise}>
              <div className={changed || cancelled ? classes.changed : null}>
                <PolicySettings
                  key={`settings-status-${state.get('cancelPolicy')}`}
                  policy={values}
                  cancelPolicy={state.get('cancelPolicy')}
                  handleCancellation={handleCancellation}
                  changed={changed}
                  segment={segment}
                  handleAddHoldCard={handleAddHoldCard}
                  accountId={accountId}
                  minDate={minDate}
                  maxDate={maxDate}
                  goToPaymentTab={goToPaymentTab}
                />

                {!viewOnly &&
                  (cancelled ? (
                    isReinstatementOrRescind || canRewritePolicy ? (
                      <Footer
                        title={cancelledFooterActionText}
                        buttonLabel={cancelledFooterActionText}
                        form={<ReinstateForm />}
                        loading={loadingPreview}
                        onClick={onSubmit}
                        setShowRewriteModal={setShowRewriteModal}
                      />
                    ) : (
                      <CancelledPolicyFooter
                        title={
                          policyStatus === PolicyStatus.InCancellation
                            ? 'This policy is pending cancellation.'
                            : 'This policy is cancelled.'
                        }
                        isAgency={isAgency}
                      />
                    )
                  ) : (
                    changed &&
                    (values.isNonRenew ? (
                      <Footer
                        title="Cancel Renewal"
                        form={<NonRenewForm />}
                        loading={loadingPreview}
                        onClick={onSubmit}
                      />
                    ) : (
                      <Footer
                        title="Save changes"
                        buttonLabel={previewPolicyCancellation ? 'Preview Policy Cancellation' : undefined}
                        form={previewPolicyCancellation && <CancellationForm />}
                        loading={loadingPreview}
                        onClick={onSubmit}
                      />
                    ))
                  ))}
                {state.getIn(['modal']) === 'addHoldCard' && (
                  <AddHoldCard
                    policy={policy}
                    onClose={closeModal}
                    onHoldCardAddition={onHoldCardAddition}
                    open={state.getIn(['modal']) === 'addHoldCard'}
                  />
                )}
                <ValidationErrorsModal
                  open={showValidationErrorsModal}
                  onClose={() => setShowValidationErrorsModal(false)}
                />
                {showRewriteModal && <RewriteModal setShowRewriteModal={setShowRewriteModal} />}
              </div>
            </Elements>
          </BasisTheoryProvider>
        );
      }}
    </Formik>
  );
};
PolicySettingsContainer.propTypes = {
  policy: PropTypes.shape({
    renewalCreditPull: PropTypes.bool,
    policyType: PropTypes.string.isRequired,
    effectiveDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    billingDayOfMonth: PropTypes.number.isRequired,
    autoRenew: PropTypes.bool,
    state: PropTypes.string.isRequired,
    term: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired
  }).isRequired,
  loadingPreview: PropTypes.bool.isRequired,
  onPreviewPolicy: PropTypes.func.isRequired,
  goToPaymentTab: PropTypes.func.isRequired,
  toast: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired
};

export default flowRight(withToast, withStore)(PolicySettingsContainer);
