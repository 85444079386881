import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  persons: {
    marginTop: theme.spacing(-4)
  },
  row: {
    padding: theme.spacing(2, 4),
    '&:not(:first-child)': {
      borderTop: `1px solid ${theme.colorPalette.beige_10}`
    }
  }
}));

export default useStyles;
