import React, { useEffect, useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Divider } from '@material-ui/core';
import { observer } from 'mobx-react';

import { Loading } from 'core/components/loading';
import { useStore } from 'core/store';
import Switch from 'core/components/switch';
import { AuthContext } from 'core/components/auth';
import PropTypes from 'prop-types';
import AlertIconWhiteFilled from 'core/assets/svg/alert-white-filled.svg';
import Label from 'core/components/label/label';
import Section from 'core/components/section';
import IndividualQuinstreetOffer from './individual-offer';

import styles from './quinstreet.styles';
import { buildQuinstreetQuoteFromPolicy, carrierMap } from './helpers';

const Quinstreet = observer(({ classes, fromPolicy }) => {
  const session = useContext(AuthContext);
  const { setFieldValue } = useFormikContext();
  const store = useStore();
  const [toggleChecked, setToggleChecked] = useState(false);
  const {
    quote: { requestQuinstreetQuoteAction, loading, quinstreetOffers, setQuinstreetOffer },
    offer: { offer },
    account: {
      fname,
      lname,
      policies: { policy: policyStore },
      policies
    }
  } = store;

  const offerInput = fromPolicy
    ? buildQuinstreetQuoteFromPolicy({
        list: policies.list,
        fname,
        lname,
        policy: policyStore.policy
      })
    : offer;

  useEffect(() => {
    if (quinstreetOffers && !loading) {
      setQuinstreetOffer(null);
      setFieldValue('quoteQuinstreet', false);
    }
  }, [offerInput?.id]);

  return (
    <Section title="External Offers" type="SubSection">
      <div className={classes.container}>
        <Switch
          id="quoteQuinstreet"
          name="quoteQuinstreet"
          mode="dark"
          label="Generate External Quotes"
          className={classes.switch}
          value={toggleChecked}
          onChange={(_, checked) => {
            setToggleChecked(checked);
            if (checked) {
              requestQuinstreetQuoteAction(offerInput.quote, offerInput.webUserId, session.user);
            }
          }}
        />
        {!loading && !quinstreetOffers.length && (
          <div className={classNames(classes.containerInner, classes.flexStart)}>
            {' '}
            {Object.values(carrierMap).map(({ logo }) => (
              <img src={logo} style={{ marginRight: 4 }} alt="Banner Icon" />
            ))}{' '}
          </div>
        )}
        {loading &&
          Object.values(carrierMap).map((_) => (
            <div className={classes.individualContainer}>
              <div className={classNames(classes.containerInner, classes.skeletonContainer)}>
                <Skeleton width="100%" animation="wave" height={56} className={classes.skeleton} />
              </div>
              <div className={classNames(classes.containerInner, classes.noPadding)}>
                <Divider className={classes.divider} />
              </div>
              <div className={classNames(classes.containerInner, classes.noBackground)}>
                <Loading type="secondary" noBackground />
              </div>
            </div>
          ))}
        {!loading &&
          quinstreetOffers.map((quinstreetOffer) => <IndividualQuinstreetOffer quinstreetOffer={quinstreetOffer} />)}
        <div className={classes.outlineContainer}>
          <img src={AlertIconWhiteFilled} className={classes.icon} alt="Banner Icon" />
          <Label>
            These coverages are purchased through external carriers, and are handled separately. The quotes generated
            may not reflect the final price and are subject to change prior to purchase.
          </Label>
        </div>
      </div>
    </Section>
  );
});

Quinstreet.propTypes = {
  classes: PropTypes.object.isRequired,
  fromPolicy: PropTypes.bool
};

Quinstreet.defaultProps = { fromPolicy: false };

export default withStyles(styles)(Quinstreet);
