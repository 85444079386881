import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5)
  },
  name: {
    textTransform: 'uppercase'
  }
}));

export default useStyles;
