import React, { useContext } from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { useDisabledState } from 'common/disabled-context';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import FormList from 'core/components/form-list';
import AddApplicant from 'common/components/people/add-form';
import { SchedulePP } from 'common/components/schedule-pp';
import { RentersAddressForm } from 'common/components/renters/renters-address-form';
import { RentersCoverage } from 'common/components/renters/renters-coverage';
import Applicant from '../applicant/applicant';
import Discounts from '../../discounts';
import useStyles from './renters-policy.styles';
import { useFormikContext } from 'formik';

function RentersPolicy() {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const { values } = useFormikContext();
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const { disabled } = useDisabledState();

  const {
    rentersCoverage: { coverageCD }
  } = values;

  const allowLicensedActions = policyStore.getAllowLicensedActions(session);

  const personalPropertyLimit = +coverageCD.split('/')[0];

  const sppLimits = {
    tenPctOfCoverageC: personalPropertyLimit * 0.1,
    fiftyPctOfCoverageC: personalPropertyLimit * 0.5
  };

  return (
    <>
      <FormList
        disabled={disabled || !allowLicensedActions}
        id="people"
        title="People"
        item={Applicant}
        addForm={AddApplicant}
        addLabel="Add People"
        fromPolicy
      />
      <Section title="Renters Property Details" type="SubSection">
        <Card className={classes.container}>
          <RentersAddressForm />
        </Card>
      </Section>
      <Section title="Renters Property Coverage" type="SubSection">
        <Card>
          <RentersCoverage state={policyStore.geographicState} />
        </Card>
      </Section>
      <RentersCoverage disabled={disabled} fromPolicy />
      <Discounts disabled={disabled} />
      <SchedulePP disabled={disabled} sppLimits={sppLimits} />
    </>
  );
}

export default observer(RentersPolicy);
