import React from 'react';

import { useToast } from 'core/components/toast';
import { IconButtonWithTooltip } from 'core/components/icon-button-with-tooltip';
import anonImg from 'core/assets/svg/anon.svg';

const DownloadAnonDocButton = ({ setDownloadingAnonDoc, downloadAnonPolicyDocument, d }) => {
  const toast = useToast();

  return (
    <IconButtonWithTooltip
      size="small"
      tooltip="Download Anonymized Document"
      onClick={async (e) => {
        try {
          e.preventDefault();
          e.stopPropagation();
          setDownloadingAnonDoc(true);
          const fullPolicyId = d.path.split('/')[3];
          const res = await downloadAnonPolicyDocument(fullPolicyId, d.lastModified);
          setDownloadingAnonDoc(false);
          if (res?.data?.signedUrl) {
            window.open(res.data.signedUrl, '_blank');
            toast.notify({
              type: 'success',
              message:
                'Anonymized document generated. A new window should have opened with the document. If not, please check your pop-up blocker. The document has also been placed in the Internal Documents section below which should appear after a refresh.'
            });
          } else {
            toast.notify({
              type: 'error',
              message: 'There was an error downloading the anonymized document'
            });
          }
        } catch (error) {
          setDownloadingAnonDoc(false);
          toast.notify({
            type: 'error',
            message: 'There was an error downloading the anonymized document'
          });
        }
      }}
      icon={anonImg}
    />
  );
};

export default DownloadAnonDocButton;
