const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.card_background_secondary,
    overflow: 'hidden',
    padding: theme.spacing(6, 8)
  },
  individualContainer: {
    marginBottom: theme.spacing(4),
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.button__background_primary
  },
  dividerContainer: {
    backgroundColor: theme.colors.button__background_primary,
    paddingRight: 32,
    paddingLeft: 32
  },
  icon: {
    marginRight: 16
  },
  button: {
    paddingLeft: 21
  },
  switch: {
    marginLeft: 16
  },
  noBackground: {
    backgroundColor: 'transparent'
  },
  buttonIcon: { marginRight: 10 },
  containerInner: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.button__background_primary,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 24,
    color: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0
  },
  skeleton: {
    backgroundColor: theme.colorPalette.green_75
  },
  skeletonContainer: {
    paddingTop: 12,
    paddingBottom: 12
  },
  divider: {
    backgroundColor: theme.colorPalette.green_15,
    width: '100%'
  },
  errorContainer: {
    border: `2px solid ${theme.colorPalette.green_15}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(6),
    color: theme.colorPalette.white_30,
    borderRadius: theme.sizes.roundCorners,
    marginTop: theme.spacing(-4)
  },
  outlineContainer: {
    border: `2px solid ${theme.colorPalette.green_15}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(6),
    color: theme.colorPalette.white_30,
    borderRadius: theme.sizes.roundCorners,
    marginTop: theme.spacing(4)
  },
  addressFormfield: {
    paddingBottom: 8
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  disclosure: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -110
  },
  flexStart: {
    justifyContent: 'flex-start'
  },
  header: { display: 'flex', alignItems: 'center', fontSize: '40px', fontWeight: 300 },
  bigSlash: { fontSize: '70px', color: theme.colorPalette.green_15, fontWeight: 100, margin: theme.spacing(0, 1) },
  beige: { color: '#DDD1C0' },
  dollarIcon: { fontSize: '16', marginRight: 5 },
  thinText: { fontWeight: 400, fontSize: 10, marginLeft: 10, color: theme.colorPalette.beige_10, display: 'block' },
  smallText: { fontSize: 10, marginLeft: 10, color: theme.colorPalette.beige_10, fontWeight: 600, display: 'block' },
  errorText: {
    fontSize: 14,
    marginLeft: 10,
    color: theme.colors.error,
    fontWeight: 600,
    marginTop: -10
  },
  smallIcon: {
    width: 16,
    height: 16
  }
});

export default styles;
