import React, { useState, useCallback } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { AgencyPortalHeader } from './components/header';
import { AgencyTabs } from './components/agency-tabs';
import { Footer } from './components/footer';
import routes from './routes';

const PortalPage = ({ src, title }) => {
  return (
    <iframe
      src={src}
      title={title}
      style={{
        width: '100%',
        height: 'calc(100vh - 350px)',
        border: '1px solid #ccc',
        borderRadius: '10px',
        padding: 'none',
        boxShadow: '0 2px 2px #ccc '
      }}
    />
  );
};

const AgencyPortal = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(() => {
    const pathName = history.location.pathname.slice(8);
    return routes.findIndex((route) => route.route === pathName);
  });

  const handleTabChange = useCallback(
    (_, tab) => {
      setActiveTab(tab);
      history.push(`/portal/${routes[tab].route}`);
    },
    [setActiveTab, routes, history]
  );

  return (
    <>
      <AgencyPortalHeader />
      <AgencyTabs activeTab={activeTab} handleTabChange={handleTabChange} setActiveTab={setActiveTab} />
      <Switch>
        {routes.map(({ route, src, title }) => (
          <Route key={route} path={`/portal/${route}`}>
            <PortalPage src={src} title={title} />
          </Route>
        ))}
      </Switch>
      <Footer activeTab={activeTab} />
    </>
  );
};

export default AgencyPortal;
