import * as Yup from 'yup';

export const validationSchema = ({ items, tenPctOfCoverageC, fiftyPctOfCoverageC }) =>
  Yup.object().shape({
    category: Yup.string().required('Category is required!'),
    value: Yup.number()
      .required('Amount is required!')
      .test(
        'single-item-limit',
        'Individual SPP items cannot be greater than or equal to 10% of Contents Coverage C (Personal Property Coverage)',
        (value) => {
          return value < tenPctOfCoverageC;
        }
      )
      .test(
        'aggregate-item-limit',
        'Aggregate of coverage limits for SPP cannot be greater than or equal to 50% of Contents Coverage C (Personal Property Coverage)',
        (value) => {
          const totalSPPValue = items.reduce((acc, item) => acc + item.value, 0);
          return value + totalSPPValue < fiftyPctOfCoverageC;
        }
      ),
    description: Yup.string().required('Description is required!'),
    subCategory: Yup.mixed().when('category', {
      is: 'Sports',
      then: Yup.string().required('Sub Category is required!'),
      otherwise: Yup.string()
    }),
    gunsUsaWorld: Yup.mixed().when('subCategory', {
      is: 'Sports_Guns',
      then: Yup.string().required('Gun category is required!'),
      otherwise: Yup.string()
    })
  });
