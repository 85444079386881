import React from 'react';
import {
  paymentType as PaymentTypes,
  paymentMethod as PaymentMethods,
  lookupsJson,
  policyType as PolicyTypes
} from '@ourbranch/lookups';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';

import { useStore } from 'core/store';
import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import usePaymentFrequencyOptions from '../hooks/use-payment-frequency-options';

const RenewalPaymentOptions = () => {
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const { policy } = policyStore;
  const { values, setFieldValue } = useFormikContext();

  const isMortgage = values.renewalPaymentMethod === PaymentMethods.Escrow;
  const isAuto = policy.policyType === PolicyTypes.Auto;

  const { frequencyOptions, isDisabled, tooltip } = usePaymentFrequencyOptions(
    policy.renewalPaymentType,
    policy.policyType,
    policy.state
  );

  return (
    <>
      <FormField
        name="renewalPaymentMethod"
        type="select"
        label="Renewal Payment Method"
        mode="dark"
        xs={6}
        options={isAuto ? lookupsJson.autoPaymentMethod : lookupsJson.homeownersPaymentMethod}
        permissions={{ isLicensedAction: false }}
        fast={false}
        onChange={(val) => {
          if (val === PaymentMethods.Escrow) {
            setFieldValue('renewalPaymentType', PaymentTypes.Escrow);
          } else {
            // ach or credit card
            setFieldValue(
              'renewalPaymentType',
              values.renewalPaymentType === PaymentTypes.Escrow ? PaymentTypes.OneTime : values.renewalPaymentType
            );
          }
        }}
      />
      {tooltip && (
        <Grid item xs={6}>
          <LabelTooltip
            mode="dark"
            label="Renewal Payment Frequency"
            tooltip={{
              label: 'More Info',
              onHoverText: tooltip
            }}
          >
            <FormField
              name="renewalPaymentType"
              type="select"
              disabled={isMortgage || isDisabled}
              mode="dark"
              options={isMortgage ? frequencyOptions : frequencyOptions.filter((opt) => opt.id !== PaymentTypes.Escrow)}
              permissions={{ isLicensedAction: false }}
              fast={false}
            />
          </LabelTooltip>
        </Grid>
      )}
      {!tooltip && (
        <FormField
          name="renewalPaymentType"
          type="select"
          label="Renewal Payment Frequency"
          disabled={isMortgage || isDisabled}
          mode="dark"
          xs={6}
          options={isMortgage ? frequencyOptions : frequencyOptions.filter((opt) => opt.id !== PaymentTypes.Escrow)}
          permissions={{ isLicensedAction: false }}
          fast={false}
        />
      )}
    </>
  );
};

export default observer(RenewalPaymentOptions);
