import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  name: {
    marginLeft: theme.spacing(1)
  },
  checkbox: {
    marginLeft: theme.spacing(-1)
  }
}));

export default useStyles;
