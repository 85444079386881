import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';

import { useDisabledState } from 'common/disabled-context';
import { Detail } from './detail';
import { SchedulePP } from '../schedule-pp';
import { Umbrella } from '../umbrella';
import { CondoCoverage } from './condo-coverage';
import { AutoInformation } from './auto-information';

function CondoOffer({ hasAuto }) {
  const { disabled } = useDisabledState();
  const { values } = useFormikContext();
  const {
    condoCoverage: { coverageX, coverageC },
    condo: {
      condoLocation: { state }
    }
  } = values;

  const sppLimits = {
    tenPctOfCoverageC: coverageC * 0.1,
    fiftyPctOfCoverageC: coverageC * 0.5
  };

  return (
    <>
      <Detail disabled={disabled} />
      {!hasAuto && <AutoInformation disabled={disabled} />}
      <SchedulePP disabled={disabled} sppLimits={sppLimits} />
      <CondoCoverage disabled={disabled} />
      <Umbrella disabled={disabled} coverageX={coverageX} state={state} />
    </>
  );
}

CondoOffer.propTypes = {
  hasAuto: PropTypes.bool.isRequired
};

export default observer(CondoOffer);
