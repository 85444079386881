import _assignWith from 'lodash.assignwith';
import _uniqBy from 'lodash.uniqby';

// add a node here to keep its children from being overwritten
// when making a change to one
const nodesToPreserve = ['home', 'auto', 'condo', 'global', 'homeCoverage'];

const customizer = (newVal: any, srcVal: any, key: string) => {
  if (srcVal === undefined || srcVal === null) {
    return newVal;
  }
  if (newVal && nodesToPreserve.includes(key)) {
    return Object.assign(newVal, srcVal);
  }
};

export function mergeAndRemoveDuplicates(policyDetailsIn: Record<string, any>, segment: Record<string, any>) {
  const policyDetails = policyDetailsIn;
  _assignWith(policyDetails, segment, customizer);

  // now remove duplicates from the arrays where things could have been removed:
  if (policyDetails.cars) {
    policyDetails.cars = _uniqBy(policyDetails.cars, 'VIN');
  }
  if (policyDetails.drivers) {
    policyDetails.drivers = _uniqBy(policyDetails.drivers, 'id');
  }
  if (policyDetails.people) {
    policyDetails.people = _uniqBy(policyDetails.people, 'id');
  }
  if (policyDetails.trailers) {
    policyDetails.trailers = _uniqBy(policyDetails.trailers, 'VIN');
  }
  if (
    segment.scheduledPersonalProperty &&
    segment.scheduledPersonalProperty.items &&
    !segment.scheduledPersonalProperty.items.length
  ) {
    policyDetails.scheduledPersonalProperty.items = [];
  }
  if (policyDetails.fees) {
    const cancellationFees = policyDetails.fees.filter((fee: any) => fee.type === 'cancellation');
    const restFees = policyDetails.fees.filter((fee: any) => fee.type !== 'cancellation');
    policyDetails.fees = [...cancellationFees, ..._uniqBy(restFees, 'type')];
  }

  return policyDetails;
}
