import { observer } from 'mobx-react';
import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';

import { Loading } from 'core';
import { Card } from 'core/components/card';
import Section from 'core/components/section';
import { useStore } from 'core/store';
import caret from 'core/assets/svg/caretOrange.svg';
import useSession from 'core/hooks/use-session';

import FeeBreakdown from '../fee-breakdown';
import PriceBreakdown from '../price-breakdown';
import EquityBreakdown from '../equity-breakdown';
import AccountingBreakdown from '../accounting-breakdown';
import PriceSummary from '../price-summary';
import useStyles from './price-details.styles';

function PriceDetails() {
  const store = useStore();
  const policyStore = store.account.policies.policy;
  const { billingDetails, writeOffTransactions } = policyStore;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { isExternalSales } = useSession();

  return (
    <Section title="Price Details" type="SubSection">
      <Card type="secondary">
        <Collapse in={open} collapsedHeight={100}>
          <Card className={classes.container}>
            <Grid className={classes.cardHeader}>
              <PriceSummary
                billingDetails={billingDetails}
                writeOffTransactions={writeOffTransactions}
                premium={policyStore.premium}
                fees={policyStore.totalFeeCost}
                surplus={policyStore.surplusContribution}
              />
              <Button onClick={() => setOpen(!open)} variant="text" color="secondary">
                {!open ? 'See Breakdown' : 'Close Breakdown'}{' '}
                {<img className={open ? classes.icon : classes.iconFold} src={caret} alt={open ? 'fold' : 'unfold'} />}
              </Button>
            </Grid>
            {open && (
              <>
                <div className={classes.subSection}>
                  {policyStore.policyEquityStatus.status !== 'done' ? (
                    <Loading className={classes.loadingWheel} type="secondary" />
                  ) : (
                    <PriceBreakdown
                      premium={policyStore.premium}
                      fees={policyStore.totalFeeCost}
                      surplus={policyStore.surplusContribution}
                    />
                  )}
                </div>
                {!isExternalSales && (
                  <div className={classes.subSection}>
                    <AccountingBreakdown />
                  </div>
                )}
                <div className={classes.subSection}>
                  <FeeBreakdown />
                </div>
              </>
            )}
          </Card>
        </Collapse>
        {!isExternalSales && (
          <Card type="noBorders" className={classes.container}>
            <EquityBreakdown />
          </Card>
        )}
      </Card>
    </Section>
  );
}

export default observer(PriceDetails);
