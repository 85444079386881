import React from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';

import { Label } from 'core/components/label';
import { capitalize, numberFormatter } from 'core/helpers/formatters';
import useStyles from './health-insurance-info.styles';

const Item = ({ label, value }) => {
  const classes = useStyles();
  return (
    <Grid item xs={6} className={classes.residentInformation}>
      <Label type="infoLabel" className={classes.label}>
        {label}
      </Label>
      <Label type="body1" className={classes.value}>
        {Number.isNaN(Number(value)) ? capitalize(value) : `$${numberFormatter(Number(value))}`}
      </Label>
    </Grid>
  );
};

const HealthInsuranceInfo = () => {
  const { values } = useFormikContext();
  const classes = useStyles();
  return (
    <Grid container className={classes.coverages}>
      <Item label="Member’s type of health insurance" value={values.auto.pipHealthInsuranceType} />
      <Item
        label="All residents on same health insurance plan"
        value={values.auto.pipEveryoneOnSamePlan ? 'Yes' : 'No'}
      />
      <Item label="PIP Medical Coverage" value={values.autoCoverage.policyLimitPIPME} />
      <Item label="Attendant care" value={values.autoCoverage.policyLimitPIPACR} />
    </Grid>
  );
};

export default HealthInsuranceInfo;
