import React from 'react';
import { Tabs } from '@material-ui/core';

import { HeaderTab } from 'core/components/header-tab';
import routes from 'agency-portal/routes';

import useStyles from './agency-tabs.styles';

const AgencyTabs = ({ activeTab, handleTabChange }) => {
  const classes = useStyles();

  return (
    <Tabs value={activeTab} onChange={handleTabChange} className={classes.wrapper}>
      {routes.map(({ route, title, iconSrc }) => (
        <HeaderTab key={route} className={classes.tab} label={title} iconSrc={iconSrc} iconSize="small" smallPadding />
      ))}
    </Tabs>
  );
};

export default AgencyTabs;
