import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Storage } from 'aws-amplify';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';
import { generateFriendlyName } from '@ourbranch/docs-handler';

import Section from 'core/components/section';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store';
import withDatePicker from 'core/components/with-date-picker';
import { RecreateApplication } from './components/recreate-application';
import { RegularDocuments } from './components/regular-documents';
import { InternalDocuments } from './components/internal-documents';
import useStyles from './documents.styles';

function downloadDocument(path) {
  Storage.get(path, {
    customPrefix: { public: '' },
    level: 'public'
  }).then((data) => {
    window.open(data, '_blank');
  });
}

// @TODO most of these could be moved to mobx computed properties
function getDocName(path) {
  const fileName = path.substring(path.lastIndexOf('/') + 1, path.length);

  return generateFriendlyName(fileName);
}

const sortDocuments = (documents) => {
  return documents.sort((a, b) => new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime());
};

const Documents = observer(({ showPolicyIdColumn, showRecreateApplication, toast }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const {
    account: {
      id,
      policies: { getDocuments, documents, getUploadUrl, policy: policyStore }
    }
  } = useStore();

  const policyId = policyStore.policy?.id;

  const fetchDocuments = useCallback(() => {
    getDocuments(id);
  }, [getDocuments, id]);

  const progressHandler = ({ loaded, total }) => {
    const currentPercentage = (loaded / total) * 100;
    if (progress < currentPercentage) {
      setProgress(currentPercentage);
    }
  };

  const uploadComplete = (file, removeUrl) => {
    clearState();
    const { lastModified, path } = file;
    const doc = {
      path: `internal/${id}/${path}`, // @TODO use store
      lastModified,
      removeUrl
    };
    setUploaded([...uploaded, doc]);
  };

  const setErrorState = (errorMessage) => {
    setProgress(0);
    setFile(null);
    setError(errorMessage);
  };

  const clearState = () => {
    setProgress(0);
    setFile(null);
    setError(null);
  };

  const handleFetch = (url, file) => {
    const ajax = new XMLHttpRequest();
    ajax.upload.onprogress = progressHandler;
    ajax.upload.onload = uploadComplete.bind(this, file);
    ajax.upload.onerror = () => {
      setErrorState(`Couldn't upload ${file.path}`);
    };
    ajax.open('PUT', url);
    ajax.setRequestHeader('Content-Type', file.type);
    ajax.send(file);
  };

  const uploadFile = async (files) => {
    const [file] = files;
    const exists = [...documents.internal, ...uploaded].find((doc) => getDocName(doc.path) === getDocName(file.path));

    if (exists) {
      setError('You can’t upload this document, it already exists.');
      setTimeout(() => {
        setError(null);
      }, 3000);
    } else {
      setFile(file);
      setError(null);
      setProgress(0.1);
      try {
        const { data } = await getUploadUrl(id, file.name, file.type);
        if (data && data.uploadUrl) {
          const { url } = data.uploadUrl;
          handleFetch(url, file);
        } else {
          setErrorState(`Couldn't upload ${file.path}`);
        }
      } catch (err) {
        setErrorState(`Couldn't upload ${file.path}`);
      }
      if (error) {
        setTimeout(() => {
          setError(null);
        }, 3000);
      }
    }
  };

  return (
    <>
      <RegularDocuments
        getDocName={getDocName}
        sortDocuments={sortDocuments}
        showPolicyIdColumn={showPolicyIdColumn}
        downloadDocument={downloadDocument}
        fetchDocuments={fetchDocuments}
      />
      {showRecreateApplication && (
        <Section className={classNames({ [classes.docSection]: !policyId })} title="Application">
          <RecreateApplication toast={toast} />
        </Section>
      )}
      {showPolicyIdColumn && (
        <InternalDocuments
          sortDocuments={sortDocuments}
          getDocName={getDocName}
          fetchDocuments={fetchDocuments}
          downloadDocument={downloadDocument}
          uploaded={uploaded}
          uploadFile={uploadFile}
          percent={progress}
          error={error}
          file={file}
        />
      )}
    </>
  );
});

Documents.propTypes = {
  toast: PropTypes.object.isRequired,
  showPolicyIdColumn: PropTypes.bool,
  showRecreateApplication: PropTypes.bool
};

Documents.defaultProps = {
  showPolicyIdColumn: false,
  showRecreateApplication: false
};

export default flowRight(withToast, withDatePicker)(Documents);
