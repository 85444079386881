import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';
import useStyles from '../footer.styles';

const OfferOutOfDate = observer(({ onUpdate }) => {
  const { offer: store, quote: quoteStore } = useStore();
  const classes = useStyles();
  const router = useHistory();

  const needsFreshQuote = store.isOutdated;
  const sendToQuoteAndPrefill = () => {
    quoteStore.setPrefillData(store.offer.quote);
    router.push('/quote');
  };

  return (
    <>
      <Label type="whiteMedium">
        {needsFreshQuote ? 'This offer has expired. Please requote from scratch.' : 'Offer Out of Date!'}
      </Label>
      <Button
        onClick={needsFreshQuote ? sendToQuoteAndPrefill : onUpdate}
        disabled={store.loading}
        loading={store.loading}
        variant="contained"
        color="primary"
        className={classes.btn}
      >
        <Label className={classes.btnLabel}>{needsFreshQuote ? 'Requote' : 'Update To Purchase'}</Label>
      </Button>
    </>
  );
});
export default withToast(OfferOutOfDate);
