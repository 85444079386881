import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';

import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { PersonalDetails } from '../personal-details';
import useStyles from './excluded-residents.styles';
import { LabelWithTooltip } from '../components/label-with-tooltip';

const ExcludedResidents = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const excludedOrWaivedResidents = useMemo(
    () =>
      [...values.drivers, ...(values?.nonDrivers || [])].filter(
        (resident) => resident.waivedPIPWL || resident.pipOptOut
      ),
    [values.drivers, values.nonDrivers]
  );

  return (
    <Section
      title="Residents excluded or waived from coverage"
      type="InnerSection"
      className={classes.residentsSection}
    >
      {!excludedOrWaivedResidents.length && (
        <Card className={classes.noExcludedResidentsCard}>
          <Label type="body2">No residents recorded</Label>
          <Label type="body3">To change this, please modify PIP coverage</Label>
        </Card>
      )}
      {excludedOrWaivedResidents.map((person) => (
        <Card className={classes.personCard} key={person.id}>
          <PersonalDetails person={person}>
            <div className={classes.status}>
              {person.pipOptOut && (
                <LabelWithTooltip
                  label="Excluded from PIP ME"
                  tooltip="This household member has been excluded from personal injury protection medical (PIP ME) coverage either by having qualified health coverage (QHC), or personal injury protection from another auto insurance carrier."
                />
              )}
              {person.waivedPIPWL && (
                <LabelWithTooltip
                  label="Waived from WLC"
                  tooltip="This household member has been waived from work loss coverage (WLC). Residents waived from WLC will still receive personal injury protection medical (PIP ME) coverage and attendant care coverage."
                />
              )}
            </div>
          </PersonalDetails>
        </Card>
      ))}
    </Section>
  );
};

export default ExcludedResidents;
