import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';
import copy from 'copy-to-clipboard';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { AuthContext } from 'core/components/auth';
import { useToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';
import { offerHasValidOptions } from 'core/helpers/underwriting-form-helpers';
import FillSkippedDataPulls from './components/fill-skipped-data-pulls';
import OfferOutOfDate from './components/offer-out-of-date';
import NoOptionsAvailable from './components/no-options-available';
import UpdateMVRs from './components/update-MVRs';
import UpdateOffer from './components/update-offer';
import UWForm from './components/UW-form';
import Checkout from './components/checkout';
import useStyles from './footer.styles';

const Footer = observer(({ showValidationErrorsModal, setShowValidationErrorsModal }) => {
  const classes = useStyles();
  const history = useHistory();
  const toast = useToast();
  const session = useContext(AuthContext);
  const { handleSubmit, dirty, values, validateForm, setErrors, setTouched, setFieldValue, setFieldTouched } =
    useFormikContext();
  const { offer: store } = useStore();

  const agentIsLicensedForState = store.getIsLicensedForState(session);
  const { drivers } = store.offer.quote;
  const optionsAvailable = offerHasValidOptions({
    offer: store?.offer,
    priorQuoteWithPreBindUWRejections: store.priorQuoteWithPreBindUWRejections
  });

  const needToUpdateMVRs =
    !dirty &&
    values.selectedOption &&
    values.selectedOption.includes('A') &&
    drivers.some((driver) => driver.postBindMVR);

  const needToFillSkippedData = !store.isStale && !dirty && store.offer && store.offer.skippedDataPulls?.length > 0;

  const onCheckout = useCallback(async () => {
    const checkoutErrors = await validateForm();
    if (Object.keys(checkoutErrors).length > 0) {
      setErrors(checkoutErrors);
      setTouched(checkoutErrors);
      setShowValidationErrorsModal(true);
    } else {
      history.push(`/offer/${store.offer.id}/${values.selectedOption}/checkout`);
    }
  }, [history, setErrors, setTouched, store.offer.id, values, setShowValidationErrorsModal, validateForm]);

  const onContinueToUWForm = useCallback(async () => {
    const updateErrors = await validateForm();
    if (Object.keys(updateErrors).length > 0) {
      setErrors(updateErrors);
      setTouched(updateErrors);
      setShowValidationErrorsModal(true);
    } else {
      history.push(`/offer/${store.offer.id}/verifyUW`);
    }
  }, [history, setShowValidationErrorsModal, validateForm, setErrors, setTouched, store.offer.id]);

  const onUpdate = useCallback(async () => {
    const updateErrors = await validateForm();
    if (needToFillSkippedData) {
      store.fillOutSkippedDataPulls();
    } else if (Object.keys(updateErrors).length > 0) {
      setErrors(updateErrors);
      setTouched(updateErrors);
      setShowValidationErrorsModal(true);
    } else if (needToUpdateMVRs) {
      setFieldValue('getMVRs', true);
      setFieldTouched('getMVRs', true);
      handleSubmit();
    } else {
      handleSubmit();
    }
  }, [
    handleSubmit,
    setShowValidationErrorsModal,
    validateForm,
    setErrors,
    setTouched,
    needToUpdateMVRs,
    needToFillSkippedData,
    history,
    store,
    values.selectedOption
  ]);

  const copyLink = () => {
    const { protocol, host } = window.location;
    const newHost = host.replace(/staff\./i, 'www.'); // staff.ourbranch.com -> www.ourbranch.com, for example

    const selectedOption = values.selectedOption;

    let policyType;
    if (selectedOption === 'HB' || selectedOption === 'AB') {
      // if we're giving a bundled price, link to the bundle
      policyType = 'HA';
    } else {
      policyType = selectedOption;
    }

    const link = store.offer.clusterId
      ? `${protocol}//${newHost}/review?cid=${store.offer.clusterId}&planType=${store.offer.code}&policyType=${policyType}&internal=true`
      : `${protocol}//${newHost}/quote/detail/${store.offer.id}`;
    const copied = copy(link);
    if (copied) {
      toast.notify({
        type: 'success',
        message: 'The link is copied to your clipboard. This link is for customer use ONLY.'
      });
    } else {
      toast.notify({
        type: 'error',
        message: `We could not copy the link, but here it is: ${link}`
      });
    }
  };

  // Agents cannot update or checkout offers for states they are not licensed in, so don't show footer
  if (!agentIsLicensedForState) {
    return null;
  }

  return (
    <div className={classes.floatingContainer}>
      <div
        className={classNames(classes.footerContainer, {
          [classes.footerAlert]: store.isStale || !optionsAvailable
        })}
      >
        <Grid container alignItems="center" className={classes.footerContent}>
          {(store.isStale && <OfferOutOfDate onUpdate={onUpdate} />) ||
            (!optionsAvailable && <NoOptionsAvailable />) ||
            (needToUpdateMVRs && <UpdateMVRs onUpdate={onUpdate} copyLink={copyLink} />) ||
            (needToFillSkippedData && <FillSkippedDataPulls onUpdate={onUpdate} />) ||
            (dirty && <UpdateOffer onUpdate={onUpdate} />) ||
            (store.needsPreBindUWForm && (
              <UWForm
                showValidationErrorsModal={showValidationErrorsModal}
                copyLink={copyLink}
                onContinueToUWForm={onContinueToUWForm}
              />
            )) ||
            (!dirty && (
              <Checkout
                showValidationErrorsModal={showValidationErrorsModal}
                copyLink={copyLink}
                onCheckout={onCheckout}
              />
            ))}
        </Grid>
      </div>
    </div>
  );
});

Footer.propTypes = {
  showValidationErrorsModal: PropTypes.bool.isRequired,
  setShowValidationErrorsModal: PropTypes.func.isRequired
};

export default Footer;
