import React, { useMemo } from 'react';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { PersonCheckbox } from '../components/person-checkbox';
import { PersonsSection } from '../components/persons-section';

const ExcludeSelectedResidents = ({ context, onUpdate }) => {
  const driversWithQHC = useMemo(
    () => context.data.drivers.filter((driver) => driver.pipHasQHC),
    [context.data.drivers]
  );

  const excludableNonDrivers = useMemo(
    () => context.data.nonDrivers.filter((resident) => resident.pipHasQHC || !resident.pipEligible),
    [context.data.nonDrivers]
  );

  return (
    <>
      {driversWithQHC.length > 0 && (
        <PersonsSection title="Drivers">
          <Card>
            {driversWithQHC.map((driver) => (
              <PersonCheckbox
                key={driver.id}
                name={`${driver.id}-pipOptOut`}
                person={driver}
                value={driver.pipOptOut}
                onChange={(checked) => {
                  const updatedDriversList = [...context.data.drivers];
                  const index = updatedDriversList.findIndex((d) => d.id === driver.id);
                  updatedDriversList[index].pipOptOut = checked;
                  onUpdate({ drivers: updatedDriversList });
                }}
              >
                <Label type="body2Bold">Has QHC</Label>
              </PersonCheckbox>
            ))}
          </Card>
        </PersonsSection>
      )}
      {excludableNonDrivers.length > 0 && (
        <PersonsSection title="Household members">
          <Card>
            {excludableNonDrivers.map((resident, index) => (
              <PersonCheckbox
                key={resident.id}
                name={`${resident.id}.pipOptOut`}
                person={resident}
                value={resident.pipOptOut}
                onChange={(checked) => {
                  const updatedNonDriversList = [...context.data.nonDrivers];
                  const index = updatedNonDriversList.findIndex((r) => r.id === resident.id);
                  updatedNonDriversList[index].pipOptOut = checked;
                  onUpdate({ nonDrivers: updatedNonDriversList });
                }}
              >
                {resident.pipHasQHC && <Label type="body2Bold">Has QHC </Label>}
                {!resident.pipEligible && <Label type="body2Bold">Has PIP Elsewhere</Label>}
              </PersonCheckbox>
            ))}
          </Card>
        </PersonsSection>
      )}
    </>
  );
};

export default ExcludeSelectedResidents;
