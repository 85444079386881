import React from 'react';

import { Card } from 'core/components/card';
import { PersonCheckbox } from '../components/person-checkbox';
import { PersonsSection } from '../components/persons-section';

const ResidentsWithExternalPIP = ({ context, onUpdate }) => {
  return (
    <PersonsSection title="Household members">
      <Card>
        {context.data.nonDrivers
          .filter((resident) => !resident.pipHasQHC)
          .map((resident) => (
            <PersonCheckbox
              key={resident.id}
              name={`${resident.id}-hasExternalPIP`}
              person={resident}
              value={!resident.pipEligible}
              onChange={(checked) => {
                const updatedNonDriversList = [...context.data.nonDrivers];
                const index = updatedNonDriversList.findIndex((r) => r.id === resident.id);
                updatedNonDriversList[index].pipEligible = !checked;
                onUpdate({ nonDrivers: updatedNonDriversList });
              }}
            />
          ))}
      </Card>
    </PersonsSection>
  );
};

export default ResidentsWithExternalPIP;
