import React from 'react';

import alertIcon from 'core/assets/svg/alert-white-filled.svg';
import { Label } from 'core/components/label';
import { NotificationCard } from 'core/components/notification-card';
import useStyles from './note.styles';

const Note = () => {
  const classes = useStyles();
  return (
    <NotificationCard type="light" icon={alertIcon} className={classes.noteCard}>
      <div className={classes.text}>
        <Label type="body2Bold">NOTE</Label>
        <Label type="body2">
          Proof of Qualified Health Coverage (QHC) or proof of PIP elsewhere will be required post-purchase for each
          excluded household member. Making changes to PIP coverage can have an impact on price.
        </Label>
      </div>
    </NotificationCard>
  );
};

export default Note;
