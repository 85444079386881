export const CognitoPermissionGroups = {
  // Multi feature access
  isService: 'isService',
  isSales: 'isSales',
  isInternalSales: 'isInternalSales',
  isExternalSales: 'isExternalSales',
  isTeamLeader: 'isTeamLeader',
  isAgency: 'isAgency',
  isInternalAgent: 'isInternalAgent',
  isUnlicensed: 'isUnlicensed', // completely unlicensed, no access to quote in any state

  // Individual feature access
  canQuote: 'canQuote',
  canEditPolicies: 'canEditPolicies',
  canEditOffers: 'canEditOffers',
  canViewPolicies: 'canViewPolicies',
  canViewOffers: 'canViewOffers',
  canEdit: 'canEdit',
  canBind: 'canBind',
  canViewCheckoutPage: 'canViewCheckoutPage',
  canReinstate: 'canReinstate',
  canRescindCancellation: 'canRescindCancellation',
  canSeeFullOfferDetails: 'canSeeFullOfferDetails',
  canScrubIncidents: 'canScrubIncidents',
  canModifyAffinityAndLeadSource: 'canModifyAffinityAndLeadSource',
  canBackDate: 'canBackDate',
  canUploadDocuments: 'canUploadDocuments',
  canModifyBillingId: 'canModifyBillingId',
  canViewClarionDoorData: 'canViewClarionDoorData',
  canAddCarsManually: 'canAddCarsManually',
  canAutoRenew: 'canAutoRenew',
  canAddHoldCards: 'canAddHoldCards',
  canChangeExclusions: 'canChangeExclusions',
  canToggleEmployeeDiscount: 'canToggleEmployeeDiscount',
  canClearUDRs: 'canClearUDRs',
  viewOnly: 'viewOnly',
  canEditHomeAddress: 'canEditHomeAddress',
  canEditEffectiveDate: 'canEditEffectiveDate',
  canFlagAsSuspicious: 'canFlagAsSuspicious',
  canManuallyChargeFee: 'canManuallyChargeFee',
  canQuoteQuinstreet: 'canQuoteQuinstreet',
  canClearUWFormRejections: 'canClearUWFormRejections',
  canManuallyChargePolicy: 'canManuallyChargePolicy',
  canRewritePolicy: 'canRewritePolicy',
  canRemoveExcludedDriver: 'canRemoveExcludedDriver',
  canCancelReasonCNIP: 'canCancelReasonCNIP',
  canCancelReasonCNMS: 'canCancelReasonCNMS',
  canRejectPIP: 'canRejectPIP',
  showAgencyPortal: 'showAgencyPortal',
  canViewQlikReports: 'canViewQlikReports',
  canDownloadAnonDocs: 'canDownloadAnonDocs',
  canReinstateCNPY: 'canReinstateCNPY',
  canToggleCreditCheck: 'canToggleCreditCheck'
};

export const PermissionRequireTypes = {
  All: 'all',
  AtLeastOne: 'atLeastOne'
};
