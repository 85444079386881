import { makeStyles } from '@material-ui/core/styles';

import { hexToRGB } from 'core/helpers/styles-helpers';

const useStyles = makeStyles((theme) => ({
  residents: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(4, 1fr)'
  },
  residentInformation: {
    width: 100,
    padding: 0,
    '&:not(:first-child)': {
      borderLeft: `1px solid ${hexToRGB(theme.colorPalette.green_15, 0.5)}`,
      paddingLeft: theme.spacing(2)
    }
  },
  label: {
    fontWeight: 600
  },
  value: {
    color: theme.colorPalette.green_15,
    lineHeight: 1.5
  }
}));

export default useStyles;
