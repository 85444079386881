import { Grid, IconButton } from '@material-ui/core';
import React from 'react';

import agencySupport from 'core/assets/svg/agency-support.svg';
import chatBubble from 'core/assets/svg/chat-bubble.svg';
import phone from 'core/assets/svg/phone.svg';
import { openFrontChatPopup } from 'core/components/front-chat';
import useStyles from './agency-support-banner.styles';

function AgencySupportBanner({ content }) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container className={classes.container}>
        <Grid item className={classes.icon}>
          <img src={agencySupport} alt="support icon" />
        </Grid>
        <Grid item className={classes.content}>
          <h3 className={classes.heading}>Agency Support</h3>
          <div className={classes.text}>{content}</div>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <div className={classes.contact}>
          <IconButton className={classes.button} type="button" onClick={openFrontChatPopup}>
            <img src={chatBubble} alt="contact icon" />
          </IconButton>
          <span className={classes.contactText}>Chat with us</span>
        </div>
        <div className={classes.contact}>
          <IconButton className={classes.button} type="button" href="tel:+18554385411">
            <img src={phone} alt="phone icon" />
          </IconButton>
          <span className={classes.contactText}>Call (855) 438-5411</span>
        </div>
      </Grid>
    </Grid>
  );
}

export default AgencySupportBanner;
