import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  noteCard: {
    margin: 0,
    boxShadow: 'none',
    padding: theme.spacing(6)
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
  }
}));

export default useStyles;
