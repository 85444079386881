import React from 'react';

import useStyles from './row.styles';

const Row = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.row}>{children}</div>;
};

export default Row;
